import React from 'react';
import { useParams } from 'react-router-dom';

const UpdateStatus = () => {
  const { inwardId,statusId } = useParams();

  return (
    <div>
      <h2>Update Status for ID: {statusId}</h2>
      <h2>Update Inward ID: {inwardId}</h2>
      {/* Your component content */}
    </div>
  );
};

export default UpdateStatus;