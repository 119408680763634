import React, { useState, useEffect, useRef } from 'react'
// import '../inward/inwarddbstatus.css';
import Outcontainerheader from './Outcontainerheader';
import OutwardLogService from '../../services/outwardlog.service';
import { Link, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import OutwardManifest from '../../services/manifest.service';
import moment from 'moment';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import Select from 'react-select';




const Outwarddbstatus = () => {

    // const [showtable, setShowTable] = useState(true)
    const [showform, setShowForm] = useState(false);
    const [tableWidth, setTableWidth] = useState('100%');

    const ShowTable = () => {
        setTableWidth(tableWidth === '100%' ? '70%' : '100%');
        if (showform == false) {
            // setShowTable(true)
            setShowForm(true)


        } else {
            // setShowTable(false)
            setShowForm(false)
        }


    }
    
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [meniDetails, setmeniDetails] = useState([]);
const [manifestId, setmanifestId] = useState(0);


    const getMeniList = () => {
        OutwardManifest.getPendingmanifest().then(
            (data) => {
                console.log("data" + JSON.stringify(data));
                setRowData(data);
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");

        })
    }

    useEffect(() => {
        getMeniList()
    }, [])

    const handleRowClick = (meniId: number) => {
        setmanifestId(meniId)
        OutwardManifest.getManifestDetailsbyId(meniId).then(
            (data) => {
                console.log("data", data)
                setmeniDetails(data);
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    };
    const [outwarddetailStatus, setoutwarddetailStatus] = useState(0);

    const [receivedBy, setreceivedBy] = useState('');
    const [remark, setremark] = useState('');
    const [outwardDate, setoutwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [outwardTime, setoutwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
    const [showSign, setShowSign] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusReasons, setstatusReasons] = useState([]);
    const [outwarddetailList, setoutwarddetailList] = useState([]);
    const [ReasonId, setReasonId] = useState(0);
    const [ReasonLabel, setReasonLabel] = useState('');
    const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setoutwarddetailStatus(Number(event.target.value));
        if (event.target.value === '8') {
            setShowSign(true)
        } else {
            setShowSign(false)
        }
        if (event.target.value === '11') { setReasonId(0) }
    };
    const signatureCanvasRef = useRef<any>(null);


    const HandleSubmit = () => {
        if ((outwarddetailStatus !== null) && (outwarddetailList.length !== 0)) {
            let deliverySignbase64 = "";
            if (signatureCanvasRef.current) {
                deliverySignbase64 = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
                // setdeliverySign(signatureImage);
            }
            // console.log("deliverySignbase64", deliverySignbase64);

            const outwarddetailid = outwarddetailList.map((outId: any) => {
                return outId.outwardDetailsId
            })
            // inwarddetailid.push(inwardId)
            OutwardLogService.postupdateoutwarddetailstatus(outwarddetailid, outwarddetailStatus, receivedBy, remark, outwardDate, outwardTime, deliverySignbase64, ReasonId, ReasonLabel, manifestId).then(
                (data) => {
                    if (data) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: "Outward Status Updated Successfully",
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                title: 'swal-fire-alert', // Add your custom CSS class here
                            },
                        }).then(function () {
                            navigate('../outwarddetailslist');
                        });
                    }
                    // console.log(data.statuscode)
                    // if (data.statuscode === 200) {
                    //     setinwarddetailStatus(data.message[0].inwarddetailStatus);
                    // }
                }
            ).catch((error) => {
                console.log(error);
                console.log("server responding");
            })
        }
    }
    function displaySign() {
    }

    const clearSignature = () => {
        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.clear();
        }
    }

    useEffect(() => {
        getRtoReasons()
    }, [outwarddetailStatus])
    const getRtoReasons = () => {
        OutwardManifest.getRtoReasons(outwarddetailStatus, 1).then(
            (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.returnReason
                    }))
                    setstatusReasons(options)
                }
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    }

    interface selectRowType {
        mode: any;
        clickToSelect: boolean;
        style: any;
        onSelect: any;
        onSelectAll: any
    }

    const selectRow: selectRowType = {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: (row: never, isSelect: any, rowIndex: any, e: any) => {
            if (isSelect) {
                // const rowsValues = {inwardDetailsId : row['id'], tCode : Date.now().toString(), mailRoomId : mailRoomId, branch : branch, createdBy : createdBy}
                // setselectedRecord([...selectedRecord, rowsValues]) ;
                setoutwarddetailList([...outwarddetailList, row])
            } else {
                const secondIndex = outwarddetailList.findIndex((Selectrow: any) => Selectrow['outwardDetailsId'] === row['outwardDetailsId']);
                outwarddetailList.splice(secondIndex, 1);
                console.log("outwarddetailList", outwarddetailList)
            }
        },
        onSelectAll: async (isSelect: any, rows: any, e: any) => {
            if (isSelect) {
                var IdArray: [] = [];
                rows.map(async (rowDatas: never) => {
                    IdArray.push(rowDatas);
                })
                await setoutwarddetailList(IdArray)
            } else {
                await setoutwarddetailList([])
            }
        },
        style: (row: any, rowIndex: any) => {
            const backgroundColor = rowIndex >= 0 ? '#00BFFF' : '#00FFFF';
            return { backgroundColor };
        }
    }
    const dateformater = (data: any, row: any) => {
        return (moment(data)).format('DD/MM/YYYY')
    }
    const columns: ColumnDescription<selectRowType>[] = [
        {
            dataField: 'outwardDetailsId', formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1;
                // return cell;
            }, text: 'Sr No', align: 'center'
        },
        { dataField: 'AWB', text: 'AWB No' },
        { dataField: 'outwardDate', text: 'Outward Date', headerAlign: 'left', formatter: dateformater },
        { dataField: 'outwardType', text: 'Type', headerAlign: 'left', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes: "showinline" },
        { dataField: 'outwardQuantity', text: 'Pcs.', align: 'center', style: { fontWeight: 'bold', width: "75px" } },
        { dataField: 'consignerName', text: 'Consignor', align: 'center' },
        { dataField: 'employeeName', text: 'Employee', align: 'center' },


    ];
    const filteredData = Array.isArray(meniDetails)
        ? meniDetails.filter((selectedRecords) => {
            const values = Object.values(selectedRecords);
            return values.some(
                (value: unknown): boolean => {
                    if (typeof value === 'string' || typeof value === 'number') {
                        const stringValue = String(value).toLowerCase();
                        return stringValue.includes(searchQuery.toLowerCase());
                    }
                    return false;
                }
            );
        })
        : [];
    return (
        <>
            <Outcontainerheader />
            <div className='mb-10'>
                <div style={{ margin: "0px" }}>
                    <div className='' style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                        <div className="container col-10" style={{ padding: '0px' }}>
                            <div className="themeColor"  > Update Outward Status  </div>
                            <div className='col-12 inline' style={{ padding: '2%' }}>
                                <div className='col-4'>
                                    <table onClick={ShowTable} className="table-bordered" >
                                        <thead>
                                            <tr style={{ textAlign: "left", border: "1px solid grey" }}>
                                                <th scope="col" style={{ width: "15%" }}>Sr No</th>
                                                <th scope="col"  style={{ width: "30%" }}>Date Time</th>
                                                <th scope="col"  style={{ width: "30%" }}>Menifest No</th>
                                                <th scope="col"  style={{ width: "30%" }}>Staff</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ border: "1px solid grey"}}>

                                            {rowData?.map((item, index) => {
                                                return (
                                                    <tr key={"menifest" + index} onClick={() => handleRowClick(item['id'])}>
                                                        <td  style={{ width: "15%" }}>{index + 1}</td>
                                                        <td  style={{ width: "30%" }}>{moment(item['drsDate']).format("DD/MM/yyyy")}</td>
                                                        <td  style={{ width: "30%" }}>{item['manifestNumber']}</td>
                                                        <td  style={{ width: "30%", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item['deliveryBoyName']}</td>

                                                    </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-8'>
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            keyField='outwardDetailsId'
                                            striped
                                            hover
                                            condensed
                                            data={filteredData}
                                            filter={filterFactory()}
                                            filterPosition="top"
                                            columns={columns}
                                            selectRow={selectRow}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className='card container'>
                            <div className='col-12 inline'>
                                        <div className='col-10 inline'>
                                            <label className='Radiobtn col-2 inline' onClick={displaySign}><input type='radio' className='radio-btn'
                                                value="8"
                                                name="deliveryStatus"
                                                checked={outwarddetailStatus === 8}
                                                onChange={handleOptionChange1}

                                            /> Delivered</label>

                                            <label className='Radiobtn col-2 inline' onClick={displaySign}><input type='radio' className='radio-btn'
                                                value="11"
                                                name="deliveryStatus"
                                                checked={outwarddetailStatus === 11}
                                                onChange={handleOptionChange1}

                                            /> Undelivered</label>


                                            <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                                                value="13"
                                                name="deliveryStatus"
                                                checked={outwarddetailStatus === 13}
                                                onChange={handleOptionChange1}
                                            /> Redirected</label>

                                            <label className='Radiobtn col-2'>
                                                <input type='radio' className='radio-btn'
                                                    value="14"
                                                    name="deliveryStatus"
                                                    checked={outwarddetailStatus === 14}
                                                    onChange={handleOptionChange1}
                                                /> Unclaimed</label>
                                            <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                                                value="16"
                                                name="deliveryStatus"
                                                checked={outwarddetailStatus === 16}
                                                onChange={handleOptionChange1}
                                            /> OnHold</label>
                                        </div >
                                    </div>
                                <div className='col-12 inline' style={{ minHeight: 200 }}>
                                    <div className='col-8'>

                                        {(statusReasons.length !== 0) && (<div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Reason </label>
                                            <Select
                                                name="ReasonId"
                                                onChange={(e: any) => { setReasonId(e.value); setReasonLabel(e.label) }}
                                                options={statusReasons}
                                                value={{ value: ReasonId, label: ReasonLabel }}
                                                className='col-8'
                                            />
                                            {/* <input className='col-8'type="text"   value={remark} name='remark'onChange={e => setremark(e.target.value)} /> */}
                                        </div>)}

                                        {showSign && (<div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Received By</label>
                                            <input className='col-8' type="text"
                                                value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                                        </div>)}
                                        <div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Status Date & Time</label>
                                            <input className='col-4' type="date"
                                                value={outwardDate} name='inwardDate' onChange={e => setoutwardDate(e.target.value)} />
                                            <input className='col-4' type="time" style={{}}
                                                value={outwardTime} name='inwardTime' onChange={e => setoutwardTime(e.target.value)} />
                                        </div>

                                        <div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Remark</label>
                                            <input className='col-8' type="text" value={remark} name='remark' onChange={e => setremark(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        {showSign && (
                                            <div >
                                                <h5>Sign Box</h5>
                                                <div>
                                                    <SignatureCanvas
                                                        ref={signatureCanvasRef}
                                                        penColor="black"
                                                        canvasProps={{ width: 300, height: 100, className: 'sigCanvas border' }}
                                                    // canvasProps={{ width, height }}
                                                    />
                                                    <div>
                                                        {/* <button className='btn2 col-6' onClick={saveSignature}>Save Signature</button> */}
                                                        <button className='btn2 col-6' onClick={clearSignature}>Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>


                            </div>
                            <div className='btn d-flex justify-content-center'>
                                <button className="btn2 col-2 col-sm-1" type="button" onClick={HandleSubmit}><i className="fa fa-save"></i> Save</button>
                                <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                                <Link className="btn2 col-2 col-sm-1" to="/outwardLoglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Outwarddbstatus