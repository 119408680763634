import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;

const config = {
  headers: {    
   'Access-Control-Allow-Origin' : '*',
   'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
 }   
};
const login = async (username:string, password:string) => {
  await axios
    .post(API_URL + "login/authenticate/", {
      username,
      password,
    }, config)
    .then((response) => {
      if (response.data.message) {               
        localStorage.setItem("userDetails", JSON.stringify(response.data.message));  
        localStorage.setItem('token', JSON.stringify(response.data.message.token).slice(1, -1));
        localStorage.setItem('userId', JSON.stringify(response.data.message.id));
        localStorage.setItem('mailRoomId', JSON.stringify(response.data.message.mailRoomId));
        localStorage.setItem('userRole', JSON.stringify(response.data.message.roles).slice(1, -1));
      }
      return response.data;
    }).catch((error)=>{
        console.log(error)
    });
}


const logout = () => {
    localStorage.setItem("userDetails", '');  
    localStorage.setItem('token', '');
    localStorage.setItem("userId", '');  
    localStorage.setItem('mailRoomId', '');
    localStorage.setItem('userRole', '');
    // localStorage.clear();
    return '' ;
}

const forgotPassword = async (email :string)=>{
  await axios
    .post(API_URL + "login/forgotpassword", {
      email
    }, config)
    .then((response) => {
      if (response.data.message) {    
        return response.data.message
      }
    }).catch((error)=>{
        console.log(error)
    });
}
const updatepassword =async (password : string)=> {
  await axios
  .post(API_URL + "login/updatepassword", {
    password, id:  localStorage.getItem('userId')
  }, config)
  .then((response) => {
    if (response.data.message) {    
      return response.data.message
    }
  }).catch((error)=>{
      console.log(error)
  });
}
const Auth =()=> {

}
const AuthService = {
    login,
    logout,
    Auth,
    forgotPassword,
    updatepassword
  }
  
  export default AuthService;