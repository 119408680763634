import React, { useState, useEffect, useRef } from 'react'
import '../inward/inwarddbstatus.css';
import InwardContainerHeader from './InwardContainerHeader';
import InwardLogService from '../../services/inwardlog.service';
import {Link, useLocation,useNavigate } from 'react-router-dom';
import Sign from '../../components/Sign';
import SignatureCanvas from 'react-signature-canvas';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import Select from 'react-select';
const Inwarddbstatus = () => {
    // const [showtable, setShowTable] = useState(true)
    const [showform, setShowForm] = useState(false);
    const [tableWidth, setTableWidth] = useState('100%');

    const ShowTable = () => {
        setTableWidth(tableWidth === '100%' ? '70%' : '100%');
        if (showform == false) {
            // setShowTable(true)
            setShowForm(true)


        } else {
            // setShowTable(false)
            setShowForm(false)
        }


    }
    const [show, setShow] = useState(false);
    function hidetable() {
        if (show === false) {
            setShow(true)
        } else {
            setShow(false)
        }
    }

    const inwarddbData = [{


    }]

    const [drsNumber, setdrsNumber] = useState()
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [drsId, setdrsId] = useState(0);
    const [drsDetails, setDrsDetails] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState();


    // interface selectRowType {
    //     mode: any;
    //     clickToSelect: boolean;  
    //     style: any;
    //     onSelect: any;
    //   }
    
    //   const selectRow: selectRowType = {
    //     mode: "radio",
    //     clickToSelect: true,
    //     style: { backgroundColor: '#c8e6c9' },
    //     onSelect: (row: any) => {
    //       setSelectedRecords(row.id);
    //     }
    //   }

    // const shipmentMode = [{
    //   modeName: modeName,
    // }]
    const getDrsList = () => {
        InwardDeliverySheet.getPendingDrs().then(
            (data) => {
                // console.log("data"+JSON.stringify(data));
                setRowData(data);
                        }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");

        })
    }

    useEffect(() => {
        getDrsList()
    }, [])

    const handleRowClick = (drsIdRow: number) => {
        setdrsId(drsIdRow)
        InwardDeliverySheet.getDrsDetailsbyId(drsIdRow).then(
            (data) => {
                // console.log("data", data)
                setDrsDetails(data);
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    };
    const [inwarddetailStatus, setinwarddetailStatus] = useState(0);

    const [receivedBy, setreceivedBy] = useState('');
    const [remark, setremark] = useState('');
    const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
    const [updatedBy, setupdatedBy] = useState('');
    const [deliverySign, setdeliverySign] = useState('');
    const [showSign, setShowSign] = useState(false);
    const [hideSign, setHideSign] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusReasons, setstatusReasons] = useState([]);
    const [inwarddetailList, setinwarddetailList] = useState([]);
    const [ReasonId, setReasonId] = useState(0);
    const [ReasonLabel, setReasonLabel] = useState('');
   const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setinwarddetailStatus(Number(event.target.value));  
        if (event.target.value === '8') {
            setShowSign(true)
        } else {
            setShowSign(false)
        }
        console.log("inwarddetailStatus", inwarddetailStatus)
        if (event.target.value === '9') { setReasonId(0)}
    };
    const signatureCanvasRef = useRef<any>(null);

 
    const HandleSubmit = () => {
        if((inwarddetailStatus !== null) && (inwarddetailList.length !== 0)){
      let  deliverySignbase64 = "";
        if (signatureCanvasRef.current) {
           deliverySignbase64 = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
            // setdeliverySign(signatureImage);
        }  
    // console.log("deliverySignbase64", deliverySignbase64);
    
        const inwarddetailid = inwarddetailList.map((inwdId : any) => {
            return inwdId.inwardDetailsId
        })
// inwarddetailid.push(inwardId)
        InwardLogService.postupdateinwarddetailstatus(inwarddetailid,inwarddetailStatus, receivedBy, remark, inwardDate, inwardTime, deliverySignbase64, ReasonId, ReasonLabel, drsId).then(
            (data) => {
if(data){
    Swal.fire({
        position: 'center',
        icon: 'success',
        title: "Inward Status Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'swal-fire-alert', // Add your custom CSS class here
        },
      }).then(function () {
        navigate('../inworddetailslist');
      });
}
                // console.log(data.statuscode)
                // if (data.statuscode === 200) {
                //     setinwarddetailStatus(data.message[0].inwarddetailStatus);
                // }
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    }
    }
    function displaySign() {
    }
    
    const clearSignature = () => {
        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.clear();
        }
    }

    useEffect(()=>{
        getRtoReasons()  
    },[inwarddetailStatus])
    const getRtoReasons = () => {
        let inwarddetailStatusresponce = 0;
      if(inwarddetailStatus === 9){
        inwarddetailStatusresponce = 9
      }
        InwardDeliverySheet.getRtoReasons(inwarddetailStatusresponce, 1).then(
            (data)=>{
                if(data){
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.returnReason
                      }))
                    setstatusReasons(options)
                }
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    }
   
    interface selectRowType {
        mode: any;
        clickToSelect: boolean;  
        style: any;
        onSelect: any;    
        onSelectAll :any
      }    
      
      const selectRow: selectRowType = {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: (row :never, isSelect :any, rowIndex :any, e:any) => {
            if(isSelect){
            // const rowsValues = {inwardDetailsId : row['id'], tCode : Date.now().toString(), mailRoomId : mailRoomId, branch : branch, createdBy : createdBy}
                // setselectedRecord([...selectedRecord, rowsValues]) ;
                setinwarddetailList([...inwarddetailList, row])
            } else {
                const secondIndex = inwarddetailList.findIndex((Selectrow :any) => Selectrow['inwardDetailsId'] === row['inwardDetailsId']);
                inwarddetailList.splice(secondIndex, 1);
                // console.log("inwarddetailList", inwarddetailList)
            }
          },
          onSelectAll: async (isSelect :any, rows :any, e :any) => {
            if(isSelect){
             var IdArray :[] = [];
             rows.map(async (rowDatas:never)=>{
                 IdArray.push(rowDatas) ;
             })
             await setinwarddetailList(IdArray)
            }else {
            await setinwarddetailList([])
            }
           },
        style: (row :any, rowIndex:any) => {
        const backgroundColor = rowIndex >= 0 ? '#00BFFF' : '#00FFFF';
        return { backgroundColor };
        }
      }
      const dateformater = (data: any, row: any) => {
        return  (moment(data)).format('DD/MM/YYYY')
      }
      const columns: ColumnDescription<selectRowType>[] = [
        { dataField: 'inwardDetailsId', formatter: (cell, row, rowIndex, formatExtraData) => {
          return rowIndex + 1;
        // return cell;
        }, text: 'Sr No',  align: 'center'},
        { dataField: 'AWB', text: 'AWB No' },
        { dataField: 'inwardDate', text: 'Inward Date', headerAlign: 'left', formatter : dateformater},
        { dataField: 'inwardType', text: 'Type', headerAlign: 'left', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" },
        { dataField: 'inwardQuantity', text: 'Pcs.',  align: 'center' , style : { fontWeight :'bold', width : "75px"} },
        { dataField: 'consignerName', text: 'Consignor',  align: 'center'},
        { dataField: 'employeeName', text: 'Employee',  align: 'center'},
        { dataField: 'inwardType', text: 'Inward Type',  align: 'center'},
       
    
      ];
    const filteredData = Array.isArray(drsDetails)
    ? drsDetails.filter((selectedRecords) => {
        const values = Object.values(selectedRecords);
        return values.some(
          (value: unknown): boolean => {
            if (typeof value === 'string' || typeof value === 'number') {
              const stringValue = String(value).toLowerCase();
              return stringValue.includes(searchQuery.toLowerCase());
            }
            return false;
          }
        );
      })
    : [];
    return (
        <>
            <InwardContainerHeader />
            <div className='mb-10'>
                <div style={{ margin: "0px" }}>
                    <div className='' style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                        <div className="container col-10" style={{padding:'0px'}}>
                            <div className="themeColor"  > Update Inward Status  </div>                              
                             <div className='col-12 inline' style={{padding:'0px'}}>
                                <div className='col-4' style={{padding:'0px'}}>
                                <div className='col-12' style={{padding :'0px'}}>
                                        <div>
                                            <table onClick={ShowTable} className="container  table-bordered" >
                                                <thead>
                                                    <tr style={{ textAlign: "left", border: "1px solid grey" }}>
                                                        <th scope="col" className='left-data' style={{width :"10%"}}>Sr No</th>
                                                        <th scope="col" className='left-data' style={{width :"30%"}}>Date Time</th>
                                                        <th scope="col" className='left-data' style={{width :"30%"}}>DRS No</th>
                                                        <th scope="col" className='left-data' style={{width :"30%"}}>Staff</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ border: "1px solid grey", fontSize:'10px' }}>
                                                   
                                                    {rowData?.map((item, index) =>{
                                                        return (
                                                        // <tr key={"drs"+index} onClick={() => handleRowClick(item['drsNumber'])}>
                                                        <tr key={"drs"+index} onClick={() => handleRowClick(item['id'])}>
                                                        <td className='left-data' style={{width :"10%"}}>{index + 1}</td>
                                                        <td className='left-data' style={{width :"30%"}}>{moment(item['drsDate']).format("DD/MM/yyyy")}</td>
                                                        <td className='left-data' style={{width :"30%"}}>{item['drsNumber']}</td>
                                                        <td className='left-data' style={{width :"30%",  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item['deliveryBoyName']}</td>
                                                        
                                                    </tr>)
                                                    }   )}
                                                </tbody>
                                            </table>

                                        </div>
                                   </div>
                                </div>
                                <div className='col-8'>
                                <div className="table-responsive">
                            <BootstrapTable
                                keyField='inwardDetailsId'
                                striped
                                hover
                                condensed
                                data={filteredData}
                                filter={filterFactory()}
                                filterPosition="top"
                                columns={ columns }
                                selectRow={ selectRow }
                                />
                            </div>
                                </div>

</div>
                        
                        <div className='card container'>
                            <div className='bottombtns' >
                        <div className='col-12 inline'>
                        <div className='col-12 inline'>
                                            <label className='Radiobtn col-2 inline'><input type='radio' className='radio-btn' 
                                                value="8"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 8}
                                                onChange={handleOptionChange1}

                                            /> Delivered</label>

                                        <label className='Radiobtn col-2 inline'><input type='radio'className='radio-btn'
                                                value="9"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 9}
                                                onChange={handleOptionChange1}

                                            /> Undelivered</label>


                                            <label className='Radiobtn col-2'> <input type='radio'className='radio-btn'
                                                value="13"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 13}
                                                onChange={handleOptionChange1}
                                            /> Redirected</label>

                                            <label className='Radiobtn col-2'>
                                                 <input type='radio'className='radio-btn'
                                                value="14"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 14}
                                                onChange={handleOptionChange1}
                                            /> Unclaimed</label>
<label className='Radiobtn col-2'> <input type='radio'className='radio-btn'
                                                value="16"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 16}
                                                onChange={handleOptionChange1}
                                            /> OnHold</label>
                                     </div >
                                     <div className='col-5 inline'>
                                            {/* <label className='Radiobtn col-4'><input type='radio'className='radio-btn'
                                                value="15"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 15}
                                                onChange={handleOptionChange1}
                                            /> Reassign</label> */}
                                            {/* <label className='Radiobtn col-4'> <input type='radio'className='radio-btn'
                                                value="9"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 9}
                                                onChange={handleOptionChange1}
                                            /> </label> */}
                                            
</div> 
                                        </div>
                            </div>
                            <div className='col-12 inline' style={{minHeight:200}}>
                            <div className='col-8'>
                           
                          {(statusReasons.length !== 0) && (<div className='col-12 inline' style={{marginTop : '10px'}}>
                                <label className='col-4'>Reason </label>
                                <Select
                                        name="ReasonId"
                                        onChange={(e:any)=>{setReasonId(e.value); setReasonLabel(e.label) }}
                                        options={statusReasons}
                                        value={{ value: ReasonId, label: ReasonLabel }}
                                        className='col-8'
                                        />
                                {/* <input className='col-8'type="text"   value={remark} name='remark'onChange={e => setremark(e.target.value)} /> */}
                          </div>)}
                          
                          {showSign && (<div className='col-12 inline' style={{marginTop : '10px'}}>
                                    <label className='col-4'>Received By</label>
                                        <input className='col-8'  type="text"
                                            value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                                    </div>)}
                                <div className='col-12 inline' style={{marginTop : '10px'}}>
                                        <label className='col-4'>Status Date & Time</label>
                                        <input className='col-4' type="date"
                                            value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
                                        <input className='col-4' type="time" style={{}}
                                            value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
                                    </div>
                                    
                          <div className='col-12 inline' style={{marginTop : '10px'}}>
                                <label className='col-4'>Remark</label>
                                <input className='col-8'type="text"   value={remark} name='remark'onChange={e => setremark(e.target.value)} />
                          </div>
                            </div>
                            <div className='col-4'>
                                {showSign && (
                                <div >
                                   <h5>Sign Box</h5>
                                    <div>
                                        <SignatureCanvas
                                            ref={signatureCanvasRef}
                                            penColor="black"
                                            canvasProps={{ width: 300, height: 100, className: 'sigCanvas border' }}
                                        // canvasProps={{ width, height }}
                                        />
                                        <div>
                                            {/* <button className='btn2 col-6' onClick={saveSignature}>Save Signature</button> */}
                                            <button className='btn2 col-6' onClick={clearSignature}>Clear</button>
                                        </div>
                                    </div>                                   
                                </div>
                                )}   
                                
                            </div>
                            </div>

                           
                        </div>
                        <div className='btn d-flex justify-content-center'>
                            <button className="btn2 col-2 col-sm-1" type="button" onClick={HandleSubmit}><i className="fa fa-save"></i> Save</button> 
                            <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                            <Link className="btn2 col-2 col-sm-1" to="/inwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>

    )
}

export default Inwarddbstatus