import React, { useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import InwardLogService from '../../services/inwardlog.service';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import ReportContainerHeader from'../reports/ReportContainerHeader';
import ReportService from '../../services/report.service';
import Swal from 'sweetalert2';
import moment from 'moment';
// import MasterDataService from '../../services/masterData.service';


const InwardMail = () => {
    const [fromDate, setfromDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [toDate, settoDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [to, setto] = useState('');
    const [cc, setcc] = useState('');
    const [title, settitle] = useState('');
    const [receiver, setreceiver] = useState('');
    const [subject, setsubject] = useState('');
    const [content, setcontent] = useState('');
    const [signature, setsignature] = useState('');

  const mailRoomId = localStorage.getItem('mailRoomId');
  const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
  const API_URL = process.env.REACT_APP_BASE_URL_API;

    const mailProvider = [{
       fromDate: "",
        toDate: "",
        to: to,
        title: title,
        receiver:receiver ,
        subject: subject,
        content:content ,
        signature:signature 
}]




    const navigate = useNavigate();

    function sendMail() {

        InwardLogService.PostInwardMail(title, signature, receiver, subject, content, to, fromDate, toDate).then(
            (data) => {
                console.log("Resistration Successful");
                console.log(data.statuscode)
                if (data.statuscode === 200) {
                    settitle(data.message[0].title);
                    navigate('/dashboard');
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("Server Responding");
        }
        )
    }

     const generatemisreport = async () => {
       
        const bodyParamter = {
            reportType:0,
            fromDate: fromDate,
            toDate: toDate,
            to: to,
            title: title,
            receiver:receiver ,
            subject: subject,
            content:content ,
            signature:signature,
            mailRoomId:mailRoomId,
            branch:branch,
        }
     await ReportService.generatemisreport(
          bodyParamter
        ).then(
           (data) => {
              const link = document.createElement('a');
              link.href = API_URL+"uploads/"+data; // Replace with the actual file path
              link.download = 'misreport.xlsx';
              link.target = '_blank'; // Optional: Specify a different filename for the downloaded file
              link.click();
          }
        ).catch((error) => {
          console.log(error)
        })
      } 
      const sendmisreport = async () => {
        if ((to !== "") && (subject !== "") && (content !== "")) {
        const bodyParamter = {
            reportType:1,
            fromDate: fromDate,
            toDate: toDate,
            to: to,
            title: title,
            receiver:receiver,
            subject: subject,
            content:content ,
            signature:signature,
            mailRoomId:mailRoomId,
            branch:branch,
        }
     await ReportService.generatemisreport(
          bodyParamter
        ).then(
           (data) => {
            console.log(data)
            if (data === "200") {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "MIS Report send Succesfully!!",
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    title: 'swal-fire-alert', // Add your custom CSS class here
                  },
                }).then(function () {
                  navigate('../misreport');
                });
              }else{
                 Swal.fire({
                  position: 'center',
                  title: "Mail not send, please check the email Id",
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    title: 'swal-fire-alert', // Add your custom CSS class here
                  },
                }).then(function () {
                  navigate('../misreport');
                });
              }
          }
        )
        }
        else {
            let errorMessage = '';
            if ((to === "")) {
                errorMessage = "Please Enter email address";
            } else if (title === "") {
                errorMessage = "Please Enter Title";
            }   else if (subject === "") {
                errorMessage = "Please Enter Subject";
            } else if (content === "") {
                errorMessage = "Please Enter content";
            }

            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: errorMessage,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    title: 'swal-fire-alert',
                },
            });
        }
      } 
      
      
    return (
        <div>
            <ReportContainerHeader />

            <div style={{}}>
                <div className=''>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="redline col-8 " style={{marginTop:"20px"}}><p className='m-0' style={{ marginLeft: "10px", alignItems: "center" }}>MIS Report<a /></p></div>
                    </div>
                    <div className="form card container col-8" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                        {/* <div className=" "> */}



                        <div className="inline width mail-filters">
                           <div className="col-2 fromdate"><label>From Date</label></div>
                            <div className="filters"><input className="select" type="date" name='fromDate' value={fromDate} onChange={e => setfromDate(e.target.value)}></input></div>
                            <div className="col-2 fromdate"><label>To Date</label></div>
                            <div className="filters"><input className="select" type="date" name='toDate' value={toDate} onChange={e => settoDate(e.target.value)}></input></div>
                            <div className="col-2 fromdate">
                           
                    <a className="btn col-4 col-sm-4" type="button" onClick={generatemisreport} 
                    target="_blank"  rel="noreferrer" ><i className="fa fa-download"></i></a>  
                  </div>
                          
                          </div>
                        {/* </div> */}
                        <div className="width container card padding"style={{marginBottom:"10px"}}>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8 '> Title / Receiver</label></div>

                                <input type='text' style={{ marginBottom: "3vh" }} className='col-8 col-sm-2' name='title' value={title} onChange={e => settitle(e.target.value)} />
                                <input type='text' style={{ marginBottom: "3vh" }} className='col-8 col-sm-6' name='receiver' value={receiver} onChange={e => setreceiver(e.target.value)} />
                            </div>

                           <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8'> To Mail Id(s)</label></div>
                                <input type='text' style={{ marginBottom: "3vh" }} className='col-8 col-sm-8' name='to' value={to} onChange={e => setto(e.target.value)} />
                            </div>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8' >CC Mail Id(s) </label></div>
                                <input style={{ marginBottom: "3vh" }} type='text' className='col-8 col-sm-8' name='cc' value={cc} onChange={e => setcc(e.target.value)} />
                            </div>
                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'><label className='col-8' >Subject </label></div>
                                <input style={{ marginBottom: "3vh" }} type='text' className='col-8 col-sm-8' name='subject' value={subject} onChange={e => setsubject(e.target.value)} />
                            </div>
                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'><label className='col-8' >Content </label></div>
                                <textarea style={{ marginBottom: "3vh" }} className='col-8 col-sm-8' name='content' value={content} onChange={e => setcontent(e.target.value)} />
                            </div>
                            <div className='mail-inputs col-12 inline signature'>
                                <div className='col-3 mail-label'><label className='col-8' >Signature </label></div>
                                <textarea style={{marginTop:"2px"}} rows={5} className='col-8 col-sm-5' name='signature' value={signature} onChange={e => setsignature(e.target.value)} />
                                <div className='col-4 mail-btns'>
                                    <button className='btn2  mail-btn' onClick={sendmisreport}>Send</button>
                                    <button className='btn2 mail-btn'>Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
          
        </div>
    )
}

export default InwardMail