import React from 'react'
import {ReactComponent as LoginSVG} from "../../assets/SVG/loginSVG.svg";
import {ReactComponent as MisSVG} from "../../assets/SVG/MIS.svg";
import {ReactComponent as MasterSVG} from "../../assets/SVG/Master.svg";
import {ReactComponent as ReportSVG} from "../../assets/SVG/Report.svg";
import {ReactComponent as AccountSVG} from "../../assets/SVG/Accounts.svg";
import { ReactComponent as DashboardSVG } from '../../assets/SVG/Dashboard.svg';


const footerMobileNav = () => {
  return (
    <>
    <div className='itamsHide'>
          <a className="nav-link1 col-2 col-sm-2" href="../dashboard"> <LoginSVG /> <br />Dashbord<span className="sr-only">(current)</span></a>
          <a className="nav-link1 col-2 col-sm-2" href="/"> <AccountSVG /> <br />Transaction<span className="sr-only">(current)</span></a>
          <a className="nav-link1 col-2 col-sm-2" href="../masterhome"><MasterSVG /> <br />Master</a>
          <a className="nav-link1 col-2 col-sm-2" href="/"> <ReportSVG/> <br />Report</a>
          <a className="nav-link1 col-2 col-sm-2" href="/"> <MisSVG /> <span className="sr-only">(current)</span><br /><p className=' mo-mis'>Mis</p></a>
        </div>
    </>
  )
}

export default footerMobileNav