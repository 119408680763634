import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
// const createdBy = localStorage.getItem('userId');
// const updatedBy = localStorage.getItem('userId');
// const mailRoomId = localStorage.getItem('mailRoomId');
// const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};

const generateinwardreport = async (bodyParamter: any, ReportTypeId: number) => {
  // const tCode = Date.now().toString();
  var reportlink = "";
  if (ReportTypeId === 1) { reportlink = "inwardlog/generatereport/" } else if (ReportTypeId === 2) { reportlink = "inwarddetail/generatereport/" } else if (ReportTypeId === 3) { reportlink = "inwarddetail/generatedeliveryreport/" } else if (ReportTypeId === 4) { reportlink = "inwarddetail/generatependingreport/" }
  return await axios.post(API_URL + reportlink, bodyParamter, config).then(
    (data) => {
      if (data.data.statuscode === '200') {
        return data.data.message;
      }
    }
  ).catch(error => console.log(error))
}

const generateoutwardreport = async (bodyParamter: any, ReportTypeId: number) => {
  var reportlink = "";
  if (ReportTypeId === 1) { reportlink = "outwarddetail/generatereport/" } else if (ReportTypeId === 2) { reportlink = "outwarddetail/generatedeliveryreport/" } else if (ReportTypeId === 3) { reportlink = "outwarddetail/generateintransitreport/" } else if (ReportTypeId === 4) { reportlink = "outwarddetail/generatebillingreport/" }

  return await axios.post(API_URL + reportlink, bodyParamter, config).then(
    (data) => {
      if (data.data.statuscode === '200') {
        return data.data.message;
      }
    }
  ).catch(error => console.log(error))
}

const generatemisreport = async (bodyParamter: any) => {
  
  console.log(bodyParamter)
 return await axios.post(API_URL + "misreport/getreportbymail/", bodyParamter, config).then(
    (data) => {
      if (data.data.statuscode === '200' && data.data.message) {
        return data.data.message;
      }else{
        return data.data.statuscode
      }
    }
  ).catch(error => console.log(error))
}

const generateadminmaterialreport = async (bodyParamter: any) => {
  return await axios.post(API_URL + "adminmaterial/generatereport", bodyParamter, config).then(
     (data) => {
       console.log(data)
       if (data.data.statuscode === '200' && data.data.message) {
         return data.data.message;
       }else{
         return data.data.statuscode
       }
     }
   ).catch(error => console.log(error))
 }

const ReportService = {
  generateinwardreport,
  generateoutwardreport,
  generatemisreport,
  generateadminmaterialreport
}

export default ReportService;