import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import MasterHeader from './masterHeader';
import Footer from '../headerfooter/Footer';
import '../Masters/master1.css'
function Master1() {
    const [modeName, setmodeName] = useState('');
    return (
        <>
            <MasterHeader />
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>          
          <div className="container col-10">
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Shipment Mode List </p></div>

            <div className='container outward-all ' >
                <div className=' ' style={{}}>
                    <div className=' ' >
                        <div className=' col-sm-12 inputs'>
                            <label className='col-6 col-sm-2'> Mode</label>
                            <select className='col-8 col-sm-6' value={modeName} name='modeName' onChange={e => setmodeName(e.target.value)} >
                                <option value='courier'>courier</option>
                                <option value='delivery'>delivery</option>
                            </select>
                        </div>

                        <div className=' col-sm-12 inputs'>
                            <label className='col-6 col-sm-2'> Serv-Provider</label>
                            <select className='col-8 col-sm-6' >
                                <option>select1</option>
                                <option>select2</option>
                                <option>select3</option>

                            </select>
                        </div>


                        <div className=' col-sm-12 inputs'>
                            <label className='col-6 col-sm-2'> No of Shipment </label>
                            <select className='col-8 col-sm-6' >
                                <option>select1</option>
                                <option>select2</option>
                                <option>select3</option>


                            </select>
                        </div>



                        <div className=' col-sm-12 inputs'>
                            <label className='col-6 col-sm-2'> Delivery Staff </label>
                            <select className='col-8 col-sm-6' >
                                <option>select1</option>
                                <option>select2</option>
                                <option>select3</option>

                            </select>
                        </div>


                    </div>







                </div>
            </div>

            <div className='card container' style={{ paddingBottom: "10px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "10px", paddingBottom: "30px",alignItems:"center" }}>
                    <div className='margincard1itams'>
                        <label style={{ marginLeft: "10px" }}>abc</label>
                        <input className='margininutleft' type='text' name='' />
                    </div>
                    <div className='margincard1itams'>
                        <label style={{ marginLeft: "10px" }}>xyz</label>
                        <input className='margininutleft' type='text' name='' />
                    </div>

                    <div className='margincard1itams'>
                        <label >BranchName</label>
                        <input className='margininutleft' type='text' name='' />
                    </div>

                    <div className='inline' >
                        <Link className=' btn2' to="" style={{ width: "80px",zIndex:"0" }} >Add</Link>
                        <Link className=' btn2' to="" style={{ width: "80px",zIndex:"0" }}>Cancel</Link>

                    </div>
                </div>


                <div className='card ' style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "86%", paddingBottom: "15%", marginBottom: "10px" }}>
                    <div >
                        <p >Emp Id</p>
                        <p >9565</p>

                    </div>
                    <div >
                        <p >Emp Id</p>
                        <p >9565</p>

                    </div>
                    <div >
                        <p >Emp Id</p>
                        <p >9565</p>

                    </div>

                </div>

                <div className='container' style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <div >
                        <div>
                            <label style={{ marginRight: "10px" }}>abc</label>
                            <input type='text' name=''></input>
                        </div>
                        <div>
                            <label style={{ marginRight: "10px" }}>abc</label>
                            <input type='text' name=''></input>
                        </div>
                    </div>

                    <div >
                        <div>
                            <label style={{ marginRight: "10px" }}>abc</label>
                            <input type='text' name=''></input>
                        </div>
                        <div>
                            <label style={{ marginRight: "10px" }}>abc</label>
                            <input type='text' name=''></input>
                        </div>
                    </div>


                </div>
            </div>




            <div className='btn inline'>
                <Link className="btn2 col-2 col-sm-1" to=''><i className="fa fa-plus" aria-hidden="true"></i> Add</Link>
                <Link className="btn2 col-2 col-sm-1" to=''><i className="fa fa-edit"></i> Edit</Link>
                <Link className="btn2 col-2 col-sm-1" to=''><i className="fa fa-save"></i> Save</Link>
                <Link className="btn2 col-2 col-sm-1" to=''> Delete</Link>
                <Link className="btn2 col-2 col-sm-1" to=''><i className="fa fa-close"></i> Cancel</Link>
                <Link className="btn2 col-2 col-sm-1" to=''> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
                <Link className="btn2 col-2 col-sm-1" to=''><i className="fa fa-search" aria-hidden="true"></i> Search</Link>
            </div>
            </div>
            </div>
            <Footer />
        </>

    )
}

export default Master1