import { Navigate, useLocation } from 'react-router-dom';

// Assuming you have a function to check if the user is authenticated.
const isAuthenticated = () => {
 const Roles =  (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).roles : ""
 if((Roles !== "") || (Roles === null)){
  return true
 } else {
  return false
 }
};

const ProtectedRoute = ({ element }) => {
  const location = useLocation();

  if (isAuthenticated()) {
    return element;
  } else {
    // Redirect to the login page and pass the current location to it for redirection after login.
    return <Navigate to="../logout" state={{ from: location }} replace />;
  }
};

export default ProtectedRoute;
