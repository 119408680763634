import '../inward/InwardDelivarySheet.css';
import InwardContainerHeader from "./InwardContainerHeader";
import { useLocation} from "react-router-dom";

function DrsPrint() {
const API_URL = process.env.REACT_APP_BASE_URL_API;

const { state } = useLocation();
  
  
    return (
        <>
            <InwardContainerHeader />
                <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                    <div className="container " style={{}}>
                    <div className="themeColor width" ><p style={{ marginLeft: "10px" }}> Inward Delivery Run Sheet Entry </p></div>
                    <object data={API_URL +"drs/pdf/"+state} type="application/pdf" width="100%" height="500px">
                    <p>Unable to display PDF file. <a href={API_URL +"drs/pdf/"+state}>Download</a> instead.</p>
                    </object>
                </div>
                </div>
                </div>
        </>



    );
} export default DrsPrint;