import React from 'react'
import SettingHeader from './settingHeader'
const SettingHome = () => {
  return (
    <div>
        <SettingHeader />

    </div>
  )
}

export default SettingHome