import { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
})


function Deliverylist() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
const [allDeliveryBoy , setallDeliveryBoy]= useState([]);

const HandleDelete = () => {
  if(selectedRecords !== undefined){   
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      MasterDataService.DeleteDeliveryBoyResister(selectedRecords)
      .catch((error) => {
        console.log(error);
        console.log("server responding");
  
      })
      Swal.fire({
        title: "Deleted",
        icon: 'success',
        text: "Delivery Boy has been deleted",
    }).then(function() {
     window.location.reload()
    })
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    )  {
      swalWithBootstrapButtons.fire(
        {
          title: "Cancelled",
          icon: 'error',
          text: "Delivery Boy is safe :)",
      }).then(function() {          
     window.location.reload()
      }
      )
    }
  })
}
} 
//-------- End Table Sort ------------
interface selectRowType {
  mode: any;
  clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
  style: any;
  onSelect: any;
}

const selectRow: selectRowType = {
  mode: "radio",
  clickToSelect: true,
  style: { backgroundColor: '#c8e6c9' },
  onSelect: (row: any) => {
    setSelectedRecords(row.id);
  }
}
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [

    { dataField: 'deliveryBoyId', text: 'Delivery Boy Id' },
    { dataField: 'deliveryBoyName', text: ' Name' },
    { dataField: 'emailId', text: 'Email Id' },
    { dataField: 'phoneNumber', text: 'Phone No' }

  ];
  //Table End

  const GetdeliveryBoy = async () =>{
    await MasterDataService.GetdeliveryBoy().then(
        (data) =>{
            setallDeliveryBoy(data);
        }
    )
  }
  useEffect(()=>{
    GetdeliveryBoy();
  },[])
  
  const navigate = useNavigate();
  const EditDelivery = () =>{
    if(selectedRecords !== undefined){   
    navigate('/delivery', { state: selectedRecords });
    }
  }
  const filteredData = Array.isArray(allDeliveryBoy) ? allDeliveryBoy.filter((selectedRecords) => {
    const values = Object.values(selectedRecords);
    return values.some((value: unknown): value is string =>
  typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
);

  }) : [];
  return (
    <>
      <MasterHeader />


      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
      <div className='container col-10' >           
          <div className="">
          <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Delivery Boy List</p></div>

          <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end",alignItems:"center" }}>
              <input
                className='searchbar col-3'
                type='text'
                value={searchQuery}
                placeholder='Search Here ...'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%',textAlign:"left" }} 
              keyField='id'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />

          </div>
        </div>
      </div>
      <div className='btn d-flex justify-content-center'>
        <Link to="/delivery" className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>       
        <button className="btn2 col-2 col-sm-1" onClick={EditDelivery}><i className="fa fa-edit"></i> Edit</button>       
        <button className="btn2 col-2 col-sm-1" onClick={HandleDelete}> <i className="fa fa-trash"></i>Delete</button>
        <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
       </div>
       </div>
    </>
  );
}

export default Deliverylist