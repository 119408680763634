import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import AccountService from '../../services/accountData.service';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter';
import { read, utils, writeFile } from 'xlsx';
import Outcontainerheader from './Outcontainerheader';
import OutwardAdminMaterialService from '../../services/outwardAdminMaterial.service';
import moment from 'moment';

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}
const AdminMaterial = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [allAdminMaterial, setallAdminMaterial] = useState([])
  const [importedAdminMaterial, setimportedAdminMaterial] = useState<any | []>([])
  const createdBy = localStorage.getItem('userId');
  const updatedBy = localStorage.getItem('userId');
  const mailRoomId = localStorage.getItem('mailRoomId');
  
   //Table Start
   interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
}
const selectRow: selectRowType = {
    mode: "checkbox",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
        setSelectedRecords(row.id);
    }
}
interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
}
const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
}: Article) => (
    <div className="btn-group" role="group">
        {
            options.map((option: any) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

interface optionsType {
    sizePerPageRenderer: any
}
const options: optionsType = {
    sizePerPageRenderer
};

const dateformater = (data: any, row: any) => {
const serialNumber = parseInt(data)
const date =  new Date((serialNumber - 2) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1));
    return moment(date).format('DD/MM/YYYY')
  }
const pagination = paginationFactory(options);
const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'reqID', formatter: (cell, row, rowIndex, formatExtraData) => {  return rowIndex + 1;  }, text: 'id', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'reqID', text: 'Request Id', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'reqitemid', text: 'Request Item Id', align: 'left', headerClasses: 'left-aligned-header' } ,
    { dataField: 'ProductDescription', text: 'Product Description', align: 'left', headerClasses: 'left-aligned-header' }  ,
    { dataField: 'QuantityApproved', text: 'Quantity Approved', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'Branch', text: 'Branch', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'CourierName', text: 'Courier Name', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'ModeofDelivery', text: 'Mode Of Delivery', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'QuantityDispatched1', text: 'Quantity Dispatch', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'PODNo1', text: 'POD Number', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'DateofDispatch1', text: 'Date Of Dispatch', align: 'left', headerClasses: 'left-aligned-header',formatter :dateformater  },
    { dataField: 'ChallanNo1', text: 'Challan Number', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'ChallanDate1', text: 'Challan Date', align: 'left', headerClasses: 'left-aligned-header',formatter :dateformater  } ,
    { dataField: 'GeneratedDate', text: 'Generated Date', align: 'left', headerClasses: 'left-aligned-header' ,formatter :dateformater }  ,
    { dataField: 'Printer', text: 'Printer', align: 'left', headerClasses: 'left-aligned-header' }  ,
    { dataField: 'MailDate', text: 'Mail Date', align: 'left', headerClasses: 'left-aligned-header' ,formatter :dateformater}  ,
];
//Table End
  const HandleSubmit = () => {
    // console.log("importedAdminMaterial send", importedAdminMaterial)
    OutwardAdminMaterialService.postadminMaterial(importedAdminMaterial).then(

        ()=>{
            navigate('../adminmateriallist');
        }
    ).catch(error =>console.log(error))
   
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          AccountService.deleteZone(state).then(
            (data) => {
              if (data.statuscode.message === '200') {
              }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Admin Material Outward has been deleted",
          }).then(function () {
            navigate('../adminmateriallist');
          })
        } else if (

          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Admin Material Outward is safe :)",
            }).then(function () {
              navigate('../adminmateriallist');
            }
            )
        }
      })
  }

  //------------excel import --------------
  
  
//      const handleImport = (event: any) => {
//     if (!event.target.files) return;
//     const files = event.target.files;
//     if (files.length) {
//         const file = files[0];
//         const reader = new FileReader();
//         reader.onload = (event:any) => {
//             const wb = read(event.target.result);
//             const sheets = wb.SheetNames;
//             if (sheets.length) {
//                 const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
//                var newArr :any = [...allAdminMaterial, ...rows];
//                newArr.forEach((item: any) => {
//                 let dateofDispatch = "";
//                 if (item.DateofDispatch1 instanceof Date) {
//                    const serialNumber = parseInt(item.DateofDispatch1)
//                   const date =  new Date((serialNumber - 2) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1));
//                   item.DateofDispatch1 = moment(date).format('DD/MM/YYYY')
//                 }
//                 if (item.ChallanDate1 instanceof Date) {
//                   const serialNumber = parseInt(item.ChallanDate1)
//                  const date =  new Date((serialNumber - 2) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1));
//                  item.ChallanDate1 = moment(date).format('DD/MM/YYYY')
//                }
//                if (item.GeneratedDate instanceof Date) {
//                 const serialNumber = parseInt(item.GeneratedDate)
//                const date =  new Date((serialNumber - 2) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1));
//                item.GeneratedDate = moment(date).format('DD/MM/YYYY')
//              }
//                 return (
//               [...item, item.DateofDispatch1, item.ChallanDate1, item.GeneratedDate]
//                 )
//               });
                
//                 setallAdminMaterial(newArr);
//                 setimportedAdminMaterial(newArr)
//             }
//         }
//         reader.readAsArrayBuffer(file);

//     }
// }

const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files;
  
  if (!files || files.length === 0) return;

  const file = files[0];
  const reader = new FileReader();

  reader.onload = (e:any) => {
    const data = e.target.result;

    const wb = read(data);
    const sheets = wb.SheetNames;

    if (sheets.length > 0) {
      const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

      const newArr:any = [...allAdminMaterial, ...rows];

      newArr.forEach((item: any) => {
        console.log("item.DateofDispatch1", item.DateofDispatch1)
        if (typeof item.DateofDispatch1 === 'number') {
          item.DateofDispatch1 = parseExcelDate(item.DateofDispatch1);
        }

        if (typeof item.ChallanDate1 === 'number') {
          item.ChallanDate1 = parseExcelDate(item.ChallanDate1);
        }

        if (typeof item.GeneratedDate === 'number') {
          item.GeneratedDate = parseExcelDate(item.GeneratedDate);
        }
        if (typeof item.MailDate === 'number') {
          item.MailDate = parseExcelDate(item.MailDate);
        }        
        item.mailRoomId = mailRoomId;
        item.createdBy = createdBy;
        item.adminMaterialStatus = "0";
      });

      setallAdminMaterial(newArr);
      setimportedAdminMaterial(newArr);
    }
  };

  reader.readAsArrayBuffer(file);
};

const parseExcelDate = (serialNumber: number): string => {
  console.log("serialNumber", serialNumber)
  const date = new Date((serialNumber - 2) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1));  
  console.log("date", date)
  return moment(date).format('DD/MM/YYYY');
};
  //-------------excel import end here ---------
  
useEffect(() => {
setFilterdData();
},[searchQuery])

const setFilterdData = async () => {
  await setimportedAdminMaterial(Array.isArray(allAdminMaterial) ? allAdminMaterial.filter((searchRecords) => {
    const values = Object.values(searchRecords);
    return values.some((value: unknown): value is string =>
    typeof value === 'string' ? value.toLowerCase().includes(searchQuery.toLowerCase())
    : typeof value === 'number' ? value.toString().includes(searchQuery.toLowerCase()) : false
    )
  }): [])
}
  return (
    <>

      <div style={{ margin: "0px" }}>
      <Outcontainerheader/>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-10' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>Admin Material Outward</p></div>       

          <div className='col-12'>  
          <div className='col-12 inline' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent:"space-between", alignItems: "center" }}>
     <div className='col-6'> 
     <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
     {/* <button className='inwordbtn' onClick={uploadPincode}><i className="fa fa-upload"> </i> Upload Pincode </button> */}
     </div>
                            <input
                                className='searchbar col-3'
                                type='text'
                                value={searchQuery}
                                placeholder='Search Here ...'
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <div className="table-responsive">
                        <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "center" }}
                                keyField='reqID'
                                striped
                                hover
                                condensed
                                data={importedAdminMaterial}
                                columns={columns}
                                pagination={pagination}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                filterPosition="top"
                                        />
                        </div>
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1"
            onClick={HandleSubmit} to="../adminmateriallist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
           
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
        
          <Link className="btn2 col-2 col-sm-1" to="/adminmateriallist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default AdminMaterial