import React, { useState, useCallback } from "react";
import { BarChart, Bar, Cell, LabelList } from "recharts";

export default function InwardStatusBarChart(props: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const data = props.dataforBarChart.map((d: any) => ({
    "name": d.name,
    "value": d.value,
  }));
  const activeItem = data ? data[activeIndex] : [];
  const handleClick = useCallback(
    (entry: any, index: number) => {
      setActiveIndex(index);
    },
    [activeIndex]
  );

  return (
    <div>
      <p className="dash-head1">Inward Status Of {props.todaysDate}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span style={{justifyContent:"end"}}>{activeItem ? `${activeItem.name}: ${activeItem.value}` : ""}   </span>   </p>
     
      <BarChart width={500} height={180} data={data}>
        <Bar dataKey="value" onClick={handleClick}>
          {data.map((_entry: any, index: number) => (
            <Cell
              cursor="pointer"
              fill={index === activeIndex ? "rgb(94, 83, 83)" : "#db1c29"}
              key={`cell-${index}`}
            />
          ))}
          <LabelList
            dataKey="name"
            position="centerTop"
            content={(props: any) => {
              const { x, y, width, value } = props;
              return (
                <text x={x + width / 2} y={y} textAnchor="middle" style={{ fontSize: '10px' }}>
                  {value}
                </text>
              );
            }}
          />
        </Bar>
          
      </BarChart>    
    </div>
  );
}