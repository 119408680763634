import '../inward/inwarddetail.css';
import InwardContainerHeader from './InwardContainerHeader';
import { useEffect, useRef, useState } from "react";
import Inwarddetailsercice from "../../services/inwarddetail.service";
import InwardLogService from '../../services/inwardlog.service';
import MasterDataService from '../../services/masterData.service';
import moment from 'moment';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';


function InwordDetails() {
    const navigate = useNavigate();
    const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
    const [batchNo, setbatchNo] = useState("");
    // const [consignorbranch, setConsignorBranch] = useState((localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : 0);
    const [allBranch, setallBranch] = useState([]);
    const [consignorBranchId, setconsignorBranchId] = useState(0);
    const [consignorbranchLabel, setconsignorBranchLabel] = useState('Please Select Branch');
    // const [inwardDate1, setinwardDate1] = useState("");
    const [serviceProviderId, setserviceProviderId] = useState(0);
    const [serviceProvider, setserviceProvider] = useState("");
    const [serviceProviderList, setserviceProviderList] = useState([]);
    const [modeName, setmodeName] = useState('');
    const [AWB, setAWB] = useState("");
    const [employeeId, setemployeeId] = useState(0);
    const [remark, setremark] = useState("");
    const [companyName, setcompanyName] = useState("");
    // const [deliveryBy, setdeliveryBy] = useState("");
    // const [deliverySign, setdeliverySign] = useState("");
    // const [deliveryRemark, setdeliveryRemark] = useState("");
    // const [isMissRoute, setisMissRoute] = useState("");
    // const [missRouteReason, setmissRouteReason] = useState("");
    // const [nextConnection, setnextConnection] = useState("");
    const [modeId, setmodeId] = useState(0)
    const [shipmentmode, setshipmentmode] = useState([])
    const [allInwardLog, setAllInwardLog] = useState([]);
    const [allRegulators, setAllRegulators] = useState([]);
    const [allEmployee, setAllEmployee] = useState([]);
    const [fromSource, setFromSource] = useState<Number>(0);
    const [consignor, setConsignor] = useState(0)
    const [consignorName, setConsignorName] = useState("Please Enter Consignor Details")
    const [consignerCityName, setconsignerCityName] = useState("")
    const [refNo, setrefNo] = useState("")
    const [mailContent, setmailContent] = useState("")
    const [inwardQuantity, setinwardQuantity] = useState<any | null>(1)
    const [maxInwardQuantity, setmaxInwardQuantity] = useState<any | null>(1)
    const [entryDoneQty, setEntryDoneQty] = useState<any | null>(0)
    const [allSelections, setallSelections] = useState([])

    //employee details
    const [employeeName, setemployeeName] = useState("Please Select Employee")
    const [emailId, setemailId] = useState("");
    const [extension, setextension] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [department, setdepartment] = useState(0);
    const [departmentName, setdepartmentName] = useState('');
    const [division, setdivision] = useState("");
    const [floor, setfloor] = useState("");
    const [routeId, setrouteId] = useState(0);
    const [routeName, setrouteName] = useState("");
    const [branch, setbranch] = useState("");
    const [isrno, setisrno] = useState("");
    const [empcity, setempcity] = useState("");
    const [company, setcompany] = useState("");
    const [branchShow, setBranchShow] = useState('none');
    const { state } = useLocation();
    const [inwardlogtotalpcs, setinwardlogtotalpcs] = useState("");
    const [options, setOptions] = useState<any | []>([]);
    const [selectedOptions, setSelectedOptions] = useState(null);
  
    // Load suggestions from local storage on component mount
    useEffect(() => {
      const storedOptions = localStorage.getItem("companyNameSuggestions");
      if (storedOptions) {
        setOptions(JSON.parse(storedOptions));
      }
      
    }, []);
    const getBranchAWBNumber = () => {
        Inwarddetailsercice.getBranchAWBNumber().then((data)=>{
            console.log(data)
            setAWB(data)
          })
    }
  
    // Update suggestions in local storage whenever options change
    useEffect(() => {
        console.log("options", options.length)
        if( options.length  > 0){
      localStorage.setItem("companyNameSuggestions", JSON.stringify(options));
        }
    }, [options]);
  
    // Handle input value change
    const handleInputChange = (newValue :any) => {
      setcompanyName(newValue);
    };
  
    // Handle option creation
    const handleCreateOption = (companyName :any) => {
      const newOption:any = { label: companyName, value: companyName };
      setOptions([...options, newOption]);
      setSelectedOptions(newOption);
    };
  
    // Handle option selection
    const handleOptionChange = (selectedOption :any) => {
      setSelectedOptions(selectedOption);
    };
    
    const [optionsMailContent, setoptionsMailContent] = useState<any | []>([]);
    const [selectedOptionsmailContent, setSelectedOptionsmailContent] = useState(null);
  
    // Load suggestions from local storage on component mount
    useEffect(() => {
      const storedOptionsmailContent = localStorage.getItem("mailContentSuggestions");
      if (storedOptionsmailContent) {
        setoptionsMailContent(JSON.parse(storedOptionsmailContent));
      }
    }, []);
  
    // Update suggestions in local storage whenever options change
    useEffect(() => {
        if( optionsMailContent.length > 0){
      localStorage.setItem("mailContentSuggestions", JSON.stringify(optionsMailContent));
        }
    }, [optionsMailContent]);
  
    // Handle input value change
    const handleInputChangeMailContent = (newValue :any) => {
      setmailContent(newValue);
    };
  
    // Handle option creation
    const handleCreateOptionMailContent = (mailContent :any) => {
      const newOption:any = { label: mailContent, value: mailContent };
      setoptionsMailContent([...optionsMailContent, newOption]);
      setSelectedOptionsmailContent(newOption);
    };
  
    // Handle option selection
    const handleOptionChangeMailContent = (selectedOption :any) => {
      setSelectedOptionsmailContent(selectedOption);
    };

    const [optionscityName, setoptionscityName] = useState<any | []>([]);
    const [selectedOptionscityName, setSelectedOptionscityName] = useState(null);
  
    // Load suggestions from local storage on component mount
    useEffect(() => {
      const storedOptionscityName = localStorage.getItem("cityNameSuggestions");
      if (storedOptionscityName) {
        setoptionscityName(JSON.parse(storedOptionscityName));
      }
    }, []);
  
    // Update suggestions in local storage whenever options change
    useEffect(() => {
        if( optionscityName.length > 0){
      localStorage.setItem("cityNameSuggestions", JSON.stringify(optionscityName));
        }
    }, [optionscityName]);
  
    // Handle input value change
    const handleInputChangecityName = (newValue :any) => {
      setconsignerCityName(newValue);
    };
  
    // Handle option creation
    const handleCreateOptioncityName = (cityName :any) => {
      const newOption:any = { label: cityName, value: cityName };
      setoptionscityName([...optionscityName, newOption]);
      setSelectedOptionscityName(newOption);
    };
  
    // Handle option selection
    const handleOptionChangecityName = (selectedOption :any) => {
      setSelectedOptionscityName(selectedOption);
    };

    const AWBRef = useRef<HTMLInputElement | null>(null);
    // const employeeIdRef = useRef<HTMLInputElement | null>(null);
    // const consignorRef = useRef<HTMLInputElement | null>(null);

    const customStyles = {
        option: (provided: any, { isFocused, isSelected }: any) => ({
            ...provided,
            padding: 5,
            zIndex: 1,
            background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
        }),
        control: (prev: any) => ({
            ...prev,
            // none of react-select's styles are passed to <Control />
            padding: 0

        }),
        menu: (base: any, { isFocused, isSelected }: any) => ({
            ...base,
            zIndex: 100,
            background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
        }),
    }
    const [showtable, setShowTable] = useState(false)
    const [showform, setShowForm] = useState(true)
    // const [showRegulatorSelection, setshowRegulatorSelection] = useState(false)
    // const [showEmployeeSelection, setshowEmployeeSelection] = useState(false)
    // const [showConsigneeInput, setshowConsigneeInput] = useState(true)
    // const [selectedOption, setselectedOption] = useState();
    const [selectedConsignee, setselectedConsignee] = useState(0);
    const [lockUnlock, setlockUnlock] = useState(false);

    const lockBatchNumber = () => {
        if (batchNo !== "") {
            setlockUnlock(!lockUnlock);
        }
    }
    // const options;
    const ShowTableFn = () => {
        if(lockUnlock === true ){
            setShowForm(true) 
            setShowTable(false)   
        }
        else if (state === null) {
            setShowForm(!showform)
            setShowTable(!showtable)
        } else {
            setShowTable(false)
            setShowForm(true)
        }
    }

    useEffect(() => {
        getShipmentMode();
        getServiceProvider();
        getInwardLogList();
        getAllEmployee();
        onFromSourceChange(0)
        getInwardDetails();        
        getAllRegulators()
        ShowTableFn()
    }, [])
    const getInwardDetails = () => {
        if (state) {
            Inwarddetailsercice.getInwardDetailsById(state).then(
                (data) => {
                    if (data) {
                        setbatchNo(data.batchNo);
                        setAWB(data.AWB);
                        setserviceProviderId(data.serviceProvider);
                        setFromSource(data.inwardType)
                        setisrno(data.isrNumber)
                        setinwardDate(data.inwardDate);
                        setinwardTime(data.inwardTime)
                        setcompanyName(data.companyName)
                        setmodeId(data.modeName)
                        setConsignor(data.consignerId)
                        setConsignorName(data.consignerName)
                        setconsignerCityName(data.consignerCityName)
                        setrefNo(data.refNo)
                        setinwardQuantity(data.inwardQuantity)
                        setmaxInwardQuantity(data.inwardQuantity)
                        setemployeeId(data.employeeTableId)
                        setemployeeName(data.employeeName) 
                        onFromSourceChange(data.inwardType)
                        setconsignorBranchId(data.fromBranch)
                        setselectedConsignee(data.employeeTableId)
                        setmailContent(data.mailContent)
                        setremark(data.remark)
                        if(data.inwardType === 2){
                            getBranchEmployeeList(data.fromBranch)
                        }
                    }
                }
            )
        }
    }
    //  get branch options
    const getBranch = async () => {
        await MasterDataService.getBranch().then(
            async (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.branchName
                    }))
                    await setallBranch(options)
                    // if (state === null) {
                    //     if (data[0]) {
                    //         await setconsignorBranchId(data[0]['id'])
                    //         await setconsignorBranchLabel(data[0]['branchName'])
                    //     }
                    // } 
                }
            }
        )
    }

    useEffect(()=>{
        let branchLists = allBranch.find((item: any) => {
            return item['value'] === consignorBranchId
        });
        if (branchLists !== undefined) {
            setconsignorBranchLabel(branchLists['label'])
        }
    },[allBranch, consignorBranchId])
    const getShipmentMode = async () => {
        await MasterDataService.GetshipmentMode().then(
            async (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.modeName
                    }))
                    await setshipmentmode(options)
                    // if (state === null) {
                    //     if (data[0]) {
                    //         await setmodeId(data[0]['id'])
                    //         await setmodeName(data[0]['modeName'])
                    //     }
                    // }
                }
            }
        );

    }
    useEffect(()=>{
        let modesNames = shipmentmode.find((item: any) => {
            return item['value'] === modeId
        });
        if (modesNames !== undefined) {
            setmodeName(modesNames['label'])
        }
    },[shipmentmode, modeId])
    const getServiceProvider = async () => {
        await MasterDataService.GetserviceProvider().then(
            async (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.serviceProviderName
                    }))
                    await setserviceProviderList(options)
                    if (state === null) {
                        if (data[0]) {
                            await setserviceProviderId(data[0]['id'])
                            await setserviceProvider(data[0]['serviceProviderName'])
                        }
                    } 
                }
            }
        )
    }

    useEffect(()=>{
        let serviceProviderLists = serviceProviderList.find((item: any) => {
            return item['value'] === serviceProviderId
        });
        if (serviceProviderLists !== undefined) {
            setserviceProvider(serviceProviderLists['label'])
        }
    },[serviceProviderList, serviceProviderId])

    const getInwardLogList = async () => {
        if (state === null) {
            await InwardLogService.getInwardLogPendingList().then(
                (data) => {
                    if (data) {
                        setAllInwardLog(data);
                    }
                }
            );
        }
    }
    const getAllRegulators = async () => {
        await MasterDataService.getRegulators().then(
            (data) => {
                if (data) {
                    const datopt = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.regulatorName
                    }))
                    setAllRegulators(datopt)
                }
            }
        );
    }
    const getAllEmployee = async () => {
        await MasterDataService.getEmployeeList().then(
            (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.employeeName
                    }))
                    setAllEmployee(options);
                    // setallSelections(options)
                }
            }
        );
    }
    // useEffect(()=>{
    //     onFromSourceChange(fromSource) 
    // },[fromSource])
    const onFromSourceChange = async (value: Number) => {
        setFromSource(value);
        if (value === 1) {
            setBranchShow('none');
            await setallSelections(allRegulators)
        } else if (value === 2) {
            setBranchShow('block');
            getBranch();
            //   await  getAllEmployee();
            //   await setallSelections(allEmployee)
        } else {
            setBranchShow('none');
            await setallSelections([])
        }
    }

    const handleConsignor = async (e: any) => {
        if (e) {
            if(fromSource === 0)
            {
                await setConsignor(0);
            }else{
                await setConsignor(e.value);
            }
           await setConsignorName(e.label);
           await setconsignerCityName(e.cityName);
        }
    }
    const consigneeChange = async (e: any) => {
        await setselectedConsignee(e.value);
        setemployeeName(e.label)
    }
    useEffect(() => {
        EmployeeDetails();
    }, [selectedConsignee])
    const EmployeeDetails = async () => {
        if (selectedConsignee !== 0) {
            await MasterDataService.GetemployeeId(selectedConsignee).then(
                (empdata) => {
                    setemployeeId(empdata.id);
                    setemailId(empdata.emailId);
                    setextension(empdata.extension);
                    setphoneNumber(empdata.phoneNumber);
                    setdepartment(empdata.department);
                    setdepartmentName(empdata.departmentName);
                    setdivision(empdata.division);
                    setfloor(empdata.floor);
                    setrouteId(empdata.routeId);
                    setrouteName(empdata.routeName);
                    setbranch(empdata.BranchName);
                    setcompany(empdata.company);
                    setempcity(empdata.cityName)
                }
            )
        }
    }
    const handleEdit = () => {
        if ((serviceProviderId !== null) && (AWB !== "") && (employeeId !== 0) && (inwardQuantity !== null) && (consignorName !== "")) {
            Inwarddetailsercice.inwarddetailUpdate(
                inwardDate,
                inwardTime,
                batchNo,
                modeId,
                AWB,
                serviceProviderId,
                fromSource,
                companyName,
                consignorBranchId,
                consignor,
                consignorName,
                consignerCityName,
                employeeId,
                employeeName,
                inwardQuantity,
                routeId,
                department,
                refNo,
                remark,
                mailContent, 
                 state
            ).then(
                (data) => {
                    if (data) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: "ISR No:- " + data.isrNumber + " Updated Successfully",
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                              title: 'swal-fire-alert', // Add your custom CSS class here
                            },
                          }).then(function () {
                            navigate('../inworddetailslist');
                          });
                    }
                }
            )
        } else {
            let errorMessage = '';
            if ((inwardQuantity === null) && (employeeId === 0) && (consignorName !== "") && (AWB ==="")) {
                errorMessage = "Please Enter No of Pcs. Received And Consignor /Consignee Details And AWB Number";
                AWBRef.current?.focus();
            } else if (employeeId === 0) {
                errorMessage = "Please Enter Consignee/ Employee Name";
            }  else if (AWB === "") {
                errorMessage = "Please Enter Valid AWB Number";
                AWBRef.current?.focus();
            } else if (inwardQuantity === null) {
                errorMessage = "Please Enter No of Pcs. Received";
            } else if (consignorName === "") {
                errorMessage = "Please Enter Consignor Details";
            }

            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: errorMessage,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    title: 'swal-fire-alert',
                },
            });
        }
    }
    const handleSubmit = () => {
        if ((serviceProviderId !== null) && (AWB !== "") && (employeeId !== 0) && (inwardQuantity !== null) && (consignorName !== "")) {
            Inwarddetailsercice.inwarddetailCreate(
                inwardDate,
                inwardTime,
                batchNo,
                modeId,
                AWB,
                serviceProviderId,
                fromSource,
                companyName,
                consignorBranchId,
                consignor,
                consignorName,
                consignerCityName,
                employeeId,
                employeeName,
                inwardQuantity,
                routeId,
                department,
                refNo,
                remark,
                mailContent
            ).then(
                (data) => {
                    if (data) {
                        if (!lockUnlock) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: " Inward Details Inserted Successfully",
                                showConfirmButton: false,
                                timer: 1500,
                                customClass: {
                                  title: 'swal-fire-alert', // Add your custom CSS class here
                                },
                              }).then(function () {
                                navigate('../inworddetailslist');
                              });
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: " Inward Details Inserted Successfully",
                                showConfirmButton: false,
                                timer: 1500,
                                customClass: {
                                  title: 'swal-fire-alert', // Add your custom CSS class here
                                },
                              })
                            setAWB('')
                            setConsignor(0)
                            setConsignorName('')
                            setemployeeId(0)
                            setemailId('')
                            setempcity('')
                            setbranch('')
                            setemployeeName('')
                            setphoneNumber('')
                            setextension('')
                            setEntryDoneQty(parseFloat(entryDoneQty) + parseFloat(inwardQuantity) )
                         }
                    }
                }
            )
        } else {
            let errorMessage = '';
            if ((inwardQuantity === null) && (employeeId === 0) && (consignorName !== "") && (AWB ==="")) {
                errorMessage = "Please Enter No of Pcs. Received And Consignor /Consignee Details And AWB Number";
                AWBRef.current?.focus();
            } else if (employeeId === 0) {
                errorMessage = "Please Enter Consignee/ Employee Name";
            }  else if (AWB === "") {
                errorMessage = "Please Enter Valid AWB Number";
                AWBRef.current?.focus();
            } else if (inwardQuantity === null) {
                errorMessage = "Please Enter No of Pcs. Received";
            } else if (consignorName === "") {
                errorMessage = "Please Enter Consignor Details";
            }

            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: errorMessage,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    title: 'swal-fire-alert',
                },
            });
        }
    }
    const handleBranchChange = async (e: any) => {
        await setconsignorBranchId(e.value)
        await setconsignorBranchLabel(e.label)
        getBranchEmployeeList(e.value);
    };
    const getBranchEmployeeList = async (value: Number) => {
        await MasterDataService.getBranchEmployeeByBranchId(value).then(
            async (data) => {
                if (data) {
                    // console.log("data employee", data)
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.employeeName,
                        "consigneeAddress": d.fullAddress,
                        "pincode": d.pincode,
                        "pincodeId": d.zipCode,
                        "cityName" : d.cityName

                    }))
                    await setallSelections(options)
                    // if (state === null) {
                    //     if (data[0]) {
                    //         await setConsignor(data[0]['id'])
                    //         await setConsignorName(data[0]['employeeName'])
                    //         await setconsignerCityName(data[0]['cityName'])
                    //     }
                    // } 
                }
            }
        )
    }

    useEffect(()=>{
        if(fromSource === 2) {
        let branchwiseEmployeeLists = allSelections.find((item: any) => {
            return item['value'] === consignor
        });
        if (branchwiseEmployeeLists !== undefined) {
            setConsignorName(branchwiseEmployeeLists['label'])
             setconsignerCityName(branchwiseEmployeeLists['consigneeAddress'])
        }
        setcompanyName('ICICI PRU AMC LTD')
    }
    },[consignor, allSelections])

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
    const handleDelete = () => {
        if (state !== undefined) {
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.isConfirmed) {
                Inwarddetailsercice.deleteInwardDetails(state)
                  .catch((error) => {
                    console.log(error);
                    console.log("server responding");
      
                  })
                Swal.fire({
                  title: "Deleted",
                  icon: 'success',
                  text: "Inward Details Entry has been deleted",
                }).then(function () {
                    navigate('../inworddetailslist');
                })
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                  {
                    title: "Cancelled",
                    icon: 'error',
                    text: "Inward Details Entry is safe :)",
                  }).then(function () {
                    navigate('../inworddetailslist');
                  }
                  )
              }
            })
          }
    }
    return (
        <>
            <InwardContainerHeader />
            <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                    <div className="container">
                        <div className="themeColor width" ><p style={{ marginLeft: "10px" }}> Inward Detail Entry </p></div>
                        <div style={{ paddingBottom: '10px' }}>
                            <div className='col-12 inline'>
                                <div className="col-4">
                                    <div className="inline">
                                        <label className="col-sm-4">Batch No</label>
                                        <input className=" col-sm-6 zeroPadding" type="text" value={batchNo} name='batchNo' onChange={e => setbatchNo(e.target.value)} onClick={ShowTableFn} /><button className={"col-sm-2" + (lockUnlock) ? "themeColor" : "greenBackground"} style={{ backgroundColor: (lockUnlock) ? '' : 'green', color: 'white', padding: 0, width: '100%' }} onClick={lockBatchNumber}>{(lockUnlock) ? "Locked" : "Lock"}</button>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className='inline'>
                                        <label className="col-4 zeroPadding">Mode</label>
                                        <Select
                                            name="modeId"
                                            onChange={(e: any) => { setmodeName(e.label); setmodeId(e.value) }}
                                            options={shipmentmode}
                                            className='col-8 select'
                                            styles={customStyles}
                                            value={{ value: modeId, label: modeName }}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 inline" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <label className="col-4" style={{ paddingLeft: '0px', paddingRight: '0px' }} >Date & Time</label>
                                    <div className='col-7 inline' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <input className="col-7" style={{ paddingLeft: '0px', paddingRight: '0px' }} type="date" value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
                                        <input className=" col-5" style={{ paddingLeft: '0px', paddingRight: '0px' }} type="time" value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 inline'>
                                <div className="col-4">
                                    <div className="inline">
                                        <label className="col-sm-4">AWB No</label>
                                        <input className="col-sm-8 zeroPadding" type="text" value={AWB} onChange={(e) => { setAWB(e.target.value) }} name="AWB" ref={AWBRef} />

                                    </div>
                                    {(batchNo !="") && (<div className='col-12 text-right' style={{fontStyle:'italic', fontSize:'10px',color:'blue'}}> {entryDoneQty} out of {inwardlogtotalpcs}</div>)}
                                </div>
                                <div className="col-4">
                                    <div className='inline'>
                                        <label className="col-4" style={{ padding: 0 }}> <span style={{ fontSize: '15px' }}> Service Provider </span></label>
                                        <Select
                                            name="serviceProviderId"
                                            onChange={(e: any) => { setserviceProvider(e.label); setserviceProviderId(e.value); 
                                                //  if(fromSource === 2) { handleCreateOptioncityName('IPRU ' + e.label)} 
                                                }}
                                            options={serviceProviderList}
                                            // styles={customStyles}
                                            value={{ value: serviceProviderId, label: serviceProvider }}
                                            className='col-8'
                                        />
                                    </div>
                                </div>

                                <div className='col-4' style={{ paddingTop: '5px', fontWeight: 600 }}>
                                    <span className='col-4' >ISR No :- </span>
                                    <span className='col-8'>{isrno}</span>
                                </div>
                            </div>
                        </div>
                        {!lockUnlock && (<>
                        {showtable  && (
                            <table className="table font-size-14 text-left">
                                <thead >
                                    <tr style={{ textAlign: "center", border: "1px solid grey" }}>
                                        <th scope="col">Sr No</th>
                                        <th scope="col">Log Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Batch No</th>
                                        <th scope="col">Mode</th>
                                        <th scope="col" >Service Provider</th>
                                        <th scope="col">Pcs.</th>
                                        <th scope="col"> Entry Done</th>
                                        <th scope="col">Pending</th>
                                        <th scope="col">Remark</th>
                                        <th scope="col">Log Entry By</th>
                                    </tr>

                                </thead>

                                <tbody className="scroll-table ">
                                    {Array.isArray(allInwardLog) &&  allInwardLog.map((inward: any, index) => {
                                        if (inward.pendingQuantity > 0) {
                                            return <>
                                                <tr onClick={() => {
                                                    setbatchNo(inward.batchNo);
                                                    setserviceProviderId(inward.serviceProvider);
                                                    setserviceProvider(inward.serviceProviderName);
                                                    setmodeName(inward.shipmentModeName);
                                                    setmodeId(inward.modeName);
                                                    setShowTable(false)
                                                    setShowForm(true)
                                                    setmaxInwardQuantity(inward.pendingQuantity)
                                                    setEntryDoneQty(inward.totalEntriesDone)
                                                    setinwardlogtotalpcs(inward.noOfShipment)
                                                    if(inward.modeName === 3){
                                                        setFromSource(2)
                                                        onFromSourceChange(2) 
                                                        // setAWB(branchAWB)
                                                        getBranchAWBNumber()
                                                        setcompanyName('ICICI PRU AMC LTD')
                                                       
                                                    }else {
                                                        setFromSource(0) 
                                                        onFromSourceChange(0)  
                                                        setAWB('')
                                                    }
                                                }}>
                                                    <th scope="row" >{index + 1}</th>
                                                    <td>{(moment(inward.inwardDate)).format('DD/MM/YYYY')}</td>
                                                    <td>{(inward.inwardTime.length > 8) ? (moment(inward.inwardTime)).format('hh:mm:ss A') : inward.inwardTime}</td>
                                                    <td>{inward.batchNo}</td>
                                                    <td>{inward.shipmentModeName}</td>
                                                    <td>{inward.serviceProviderName}</td>
                                                    <td className='text-center'>{inward.noOfShipment}</td>
                                                    <td className='text-center'>{inward.totalEntriesDone}</td>
                                                    <td className='text-center'>{inward.pendingQuantity}</td>
                                                    <td>{inward.logRemarks}</td>
                                                    <td>{inward.deliveryStaff}</td>

                                                </tr>

                                            </>
                                        }
                                    })}



                                </tbody>
                            </table>)
                        } </>)}
                        {showform && (
                            <div className='width' style={{ border: "1px solid #ccc" }}>
                                <div style={{ textAlign: 'left', height: 25 }}>
                                    <div style={{ backgroundColor: 'grey', height: 25, color: 'white' }} > <label className='themeColor' style={{ color: 'white', paddingLeft: '10px' }}>From Location &nbsp;</label> <label> <input type="radio" name="fromsource" style={{ height: '12px' }} value="2" onChange={() => { onFromSourceChange(2) }} checked={fromSource === 2} /> Inter Company  &nbsp;  &nbsp; </label>  <label> <input type="radio" name="fromsource" style={{ height: '12px' }} value="1" onChange={() => { onFromSourceChange(1) }} checked={fromSource === 1}/>  Regulators  &nbsp;  &nbsp; </label>   <label> <input type="radio" name="fromsource" style={{ height: '12px' }} value="0" onChange={() => { onFromSourceChange(0) }} checked={fromSource === 0} /> Others </label> </div>
                                </div>
                                <div className='col-12 row'>
                                    <div className='col-6' style={{ paddingTop: '5px' }}>
                                        <div style={{ display: branchShow }}>
                                            <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                                <span className='col-3'>Branch</span>
                                                <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Select
                                                        name="consignorBranchId"
                                                        onChange={handleBranchChange}
                                                        options={allBranch}
                                                        value={{ value: consignorBranchId, label: consignorbranchLabel }}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3'>Consignor</span>
                                            <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <CreatableSelect 
                                                    isClearable
                                                    options={allSelections}
                                                    onChange={handleConsignor}
                                                    value={{ value: consignor, label: consignorName }}
                                                    placeholder="Enter or select a Consignor"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' >Company Name</span>
                                            
                                        <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <CreatableSelect
                                            inputValue={companyName}
                                            options={options}
                                            onInputChange={handleInputChange}
                                            onChange={handleOptionChange}
                                            onCreateOption={handleCreateOption}
                                            value={selectedOptions}
                                            placeholder="Enter or select a company name"
                                        />
                                        </div>
                                            {/* <input type="text" value={companyName} onChange={(e) => setcompanyName(e.target.value)} className='col-9' /> */}
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' >City Name</span>
                                            <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <CreatableSelect
                                            inputValue={consignerCityName}
                                            options={optionscityName}
                                            onInputChange={handleInputChangecityName}
                                            onChange={handleOptionChangecityName}
                                            onCreateOption={handleCreateOptioncityName}
                                            value={selectedOptionscityName}
                                            placeholder="Enter or select a Consignor City"
                                        />
                                        </div>
                                            {/* <input type="text" value={consignerCityName} onChange={(e) => setconsignerCityName(e.target.value)} className='col-9' /> */}
                                        </div>

                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3'>Ref No</span>
                                            <input type="text" value={refNo} onChange={(e) => setrefNo(e.target.value)} className='col-9' />
                                        </div>

                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3'>Pcs. /Qty. </span>
                                            <input
                                                    type="text"
                                                    value={inwardQuantity}
                                                    onChange={(e: any) => {
                                                        const newValue = e.target.value;
                                                        if (newValue === "" || (/^\d*$/.test(e.target.value)) && parseInt(newValue) <= maxInwardQuantity) {
                                                        setinwardQuantity(newValue);
                                                        }
                                                    }}
                                                    className='col-9 text-left'
                                                    />
                                            {/* <input type="text" max={maxInwardQuantity} value={inwardQuantity} onChange={(e: any) => { if (/^\d*$/.test(e.target.value)) { setinwardQuantity(e.target.value) } }} className='col-8 text-right' /> */}
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' > Mail Content</span>
                                            <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <CreatableSelect
                                            inputValue={mailContent}
                                            options={optionsMailContent}
                                            onInputChange={handleInputChangeMailContent}
                                            onChange={handleOptionChangeMailContent}
                                            onCreateOption={handleCreateOptionMailContent}
                                            value={selectedOptionsmailContent}
                                            placeholder="Enter or select a Mail Content"
                                        />
                                        </div>
                                            {/* <input type="text" value={mailContent} onChange={(e) => setmailContent(e.target.value)} className='col-9' /> */}
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' > Remark </span>
                                            <input type="text" value={remark} onChange={(e) => setremark(e.target.value)} className='col-9' />
                                        </div>

                                    </div>
                                    <div className='col-6' style={{ paddingTop: '5px' }}>
                                        <div className=' inline'>
                                            <div className='col-10 row inline'>
                                                <span className='col-3'>Consignee</span>
                                                <Select
                                                    onChange={consigneeChange}
                                                    options={allEmployee}
                                                    value={{ value: employeeId, label: employeeName }}
                                                    placeholder ="Please Select a Consignee"
                                                    className='col-9' />
                                            </div>
                                            <div className='col-1 inline'>
                                                <a href="./employee" target='_blank'  > <i className='fa fa-plus'></i> </a>
                                            </div>
                                            <div className='col-1 inline'>
                                                <a onClick={getAllEmployee}   > <i className='fa fa-refresh'></i></a>
                                            </div>

                                        </div>
                                        {/* <div className='col-12 row' style={{ paddingTop: '5px' }}><span className='col-3' style={{ fontWeight: '500' }}> Emp. Name: </span> <span className='col-9'>{employeeName} </span></div> */}
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' style={{ fontWeight: '500' }}>Branch : </span> <span className='col-9'>{branch} <span> &nbsp; &nbsp; &nbsp; Department : {departmentName}</span> </span>
                                        </div>
                                        {/* <div className='col-12 row'><span className='col-4' style={{fontWeight: '500'}}> Employee Id: </span> <span   className='col-8'>{employeeId} </span> </div> */}
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}> <span className='col-3' style={{ fontWeight: '500' }}> Email Id: </span> <span className='col-9'>{emailId} </span> </div>
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}>  <span className='col-3' style={{ fontWeight: '500' }}> Phone / Ext: </span> <span className='col-9'>{phoneNumber} / {extension} </span> </div>
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}><span className='col-3' style={{ fontWeight: '500' }}> Route : </span> <span className='col-9'>{routeName}  </span></div>
                                    </div>
                                </div>
                            </div>)}

                        <div className='width form' style={{ display: 'none' }}>
                            <div className='col-12 form-section-1'>
                                <div className='sec4-cols'>
                                    <div className='sec4-labels1 '><label className='inword-label'>Emp Id</label>
                                        <input className='col-8 paddingzero' type="text" value={employeeId}></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Email Id</label>
                                        <input className='col-8 paddingzero' type="email" value={emailId} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Extension</label>
                                        <input className='col-8 paddingzero' type="text" value={extension} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Mobile</label>
                                        <input className='col-8 paddingzero' type="text" value={phoneNumber} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Department</label>
                                        <input className='col-8 paddingzero' type="text" value={department}></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Division</label>
                                        <input className='col-8 paddingzero' type="text" value={division} ></input>
                                    </div>
                                </div>
                                <div className='sec4-cols' >

                                    <div className='sec4-labels1' style={{ marginTop: "-15px" }}><label className='inword-label'>City Name</label>
                                        <input className='col-8 col-sm-4 zeroPadding' type="text" value={empcity}></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Company</label>
                                        <input className='col-8 paddingzero' type="text" value={company} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Floor</label>
                                        <input className='col-8 paddingzero' type="text" value={floor} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Route Name</label>
                                        <input className='col-8 paddingzero' type="text" value={routeName} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Remark</label>
                                        <input className='col-8 paddingzero' type="text" value={remark} ></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btn d-flex justify-content-center'>
                            {(state === null) ? <>  <button className="btn2 col-2 col-sm-1" type="button" onClick={handleSubmit}><i className="fa fa-save"></i> Save</button> </> : <>
                                <button className="btn2 col-2 col-sm-1" onClick={handleEdit} ><i className="fa fa-edit"></i> Update </button>
                                <button className="btn2 col-2 col-sm-1" onClick={handleDelete}> <i className="fa fa-trash"></i> Delete</button></>}
                            <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                            <Link className="btn2 col-2 col-sm-1" to="/inwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>

                        </div>
                    </div>
                </div>

            </div>
        </>



    );
} export default InwordDetails;