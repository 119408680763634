import React, { useEffect, useState } from 'react'
// import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import Swal from 'sweetalert2';
import './master1.css'
import EmployeeList from './EmployeeList';
import moment from 'moment';

interface ReceiverProps {
  id: any;
}

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}

const HelpDesk: React.FC<ReceiverProps> = ({ }) => {


  const { state } = useLocation();
  const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'))
  const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'))
  const [EmployeeList, setEmployeeList] = useState([])
  const [employeeName, setemployeeName] = useState('Select Employee');
  const [employeeId, setemployeeId] = useState(0);
  const [employeeno, setemployeeno] = useState('')
  const [department, setdepartment] = useState('');
  const [emailId, setemailId] = useState('');
  const [costCenter, setcostCenter] = useState('');
  const [callmodeId, setcallmodeId] = useState('');
  
  const [allcallmodes, setallcallmodes] = useState([
    {
      "value": "Email",
      "label": "Email"
    },
    {
    "value": "Telephonic",
    "label": "Telephonic"
    },
    {
    "value": "Other",
    "label": "Other"
    },
  ]);

  const [allstatus, setallstatus] = useState([
    {
      "value":"Pending",
      "label": "Pending"
    },
    {
    "value": "In Progress",
    "label": "In Progress"
    },
    {
    "value": "Resolved",
    "label": "Resolved"
    },
  ]);
  const [statusId, setstatusId] = useState('');
  
  const [callmodeName, setcallmodeName] = useState('Select Call Mode');
  const [remark, setremark] = useState('');
  const [status, setstatus] = useState('Select');
  const [statusremark, setstatusremark] = useState('');
  

  const navigate = useNavigate();

  const HandleSubmit = () => {
    let errorMessage = ""
    if((employeeId !== 0) && (remark !== "")){
    MasterDataService.PostHelpDesk(inwardDate, inwardTime, employeeId,employeeName, callmodeName, remark, status, statusremark).then(
      (data) => {
        if (data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Help Desk Created Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(()=>{
            navigate("/helpdesklist");
          });
        }
      }

    ).catch((error) => {
      console.log(error);
      console.log("server Responding");
    })
  } else {
    
    if ((employeeId === 0)) {
      errorMessage = "Please Select Empoyee";
    } else {
      errorMessage = "Please Write Some Query Details";  
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  const HandleEdit = () => {
    let errorMessage = ""
    if((employeeId !== 0) && (remark !== "")){
    MasterDataService.PutHelpDesk(inwardDate, inwardTime, employeeId,employeeName ,callmodeName, remark, status, statusremark,state).then(
      (data) => {
        if (data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Help Desk Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(()=>{
            navigate("/helpdesklist");
          });
         
        }
      }

    ).catch((error) => {
      console.log(error);
      console.log("server Responding");
    })
  } else {
    
    if ((employeeId === 0)) {
      errorMessage = "Please Select Employee";
    } else {
      errorMessage = "Please Write Some Query Details";  
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    }).then(
      ()=>{
        navigate('../helpdesk', { state: state });
      }
    );
  }
  }

  // get Employee by id
  const Gethelpdeskdatabyid = async () => {
    if (state) {
      try {
        await MasterDataService.Gethelpdeskdatabyid(state).then(
          async (data) => {
            const datetime = data.callDateTime.split("T");
            // console.log('datetime',datetime);
             setinwardDate(datetime[0])
             setinwardTime(datetime[1])
             setemployeeName(data.employeeName)
             setemployeeId(data.employeeTableId)
             setcallmodeId(data.callMode)
             setcallmodeName(data.callMode)
             setstatusId(data.helpdeskStatus)
             setstatus(data.helpdeskStatus)
             setremark(data.remark)
             setstatusremark(data.statusRemark)
          }
        );
     } catch (error) {
        console.error(error);
      }

    }
  };

  const employeechange = async (e: any) => {
     setemployeeId(e.value);
     setemployeeName(e.label)
}
useEffect(() => {
  Gethelpdeskdatabyid();
}, [])
useEffect(() => {
    EmployeeDetails();
}, [employeeId])
const EmployeeDetails = async () => {
    if (employeeId !== 0) {
        await MasterDataService.GetemployeeId(employeeId).then(
            (empdata) => {
              setcostCenter(empdata.costCenterName)
              setdepartment(empdata.departmentName);
              setemailId(empdata.emailId);
              setemployeeno(empdata.employeeId)
            }
        )
    }
}


  // get country values


  useEffect(() => {
    getemployeelist()
  }, [])
  
 
  const getemployeelist = () => {
    MasterDataService.getEmployeeList().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName
          }))
          await setEmployeeList(options)
          if (state === null) {
            // if (data[0]) {
            //   await setemployeeId(data[0]['id'])
            //   await setemployeeName(data[0]['employeeName'])
            // }
          } else {
            let employeeNames = data.find((item: any) => {
              return item.id === employeeId
            });
            if (employeeNames !== undefined) {
              setemployeeName(employeeNames.employeeName)
            }
          }
        }
        }
    );
  }
  const handleModeChange = (e:any) => {
    setcallmodeName(e.value);
     }
  

  // delete service
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const handleDelete = () => {
    if (state !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          MasterDataService.DeleteHelpDesk(state)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Call details has been deleted",
          }).then(function () {
            navigate("/helpdesklist");
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Call Details are safe :)",
            }).then(function () {
              navigate("/helpdesklist");
            }
            )
        }
      })
    }
    //GetserviceProvider();
  }

  const HandleCancel = () => {
    setemployeeName("")
    setemployeeId(0)
    setcallmodeName("")
    setcostCenter("")
    setemailId("")
    setemployeeno("")
    setinwardDate("")
    setinwardTime("")
  }
  return (
    <>
      <div className='container'>
        <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}>Add Query Details</p></div>
<div className='col-12 inline'>
        <div className='col-6'>
        {/* <div className='col-sm-12 inputs'>
          <label className='col-4 col-sm-2'><b> Date Time</b></label>
          <div className='col-8 inline' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <input className="col-4" style={{ paddingLeft: '0px', paddingRight: '0px' }} type="date" value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
                                        <input className=" col-4" style={{ paddingLeft: '0px', paddingRight: '0px' }} type="time" value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
          </div>
        </div> */}
        <div className='col-sm-12 inputs'>
            <label className="col-4"><b>Date Time</b></label>
            <div className='col-8' style={{paddingLeft:"0px",paddingRight:"0px"}}>
            <input type="date" className='col-7' value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
            <input type="time" className='col-5' value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
            </div>
        </div>
        <div className='col-sm-12 inputs'>
            <label className="col-4"><b>Employee Name</b></label>
            <div className='col-8' style={{paddingLeft:"0px",paddingRight:"0px"}}>
            <Select
                name="modeId"
                onChange={(e: any) => employeechange(e)}
                options={EmployeeList}
                styles={customStyles}
                value={{ value: employeeId, label: employeeName }}
            />
            </div>
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-4'><b> Employee Id</b></label>
          <input type='text' readOnly className='col-8' name='employeeno' value={employeeno} onChange={e => setemployeeno(e.target.value)} />
        </div>

        <div className=' col-sm-12 inputs'>
          <label className='col-4'><b> Department </b></label>
          <input type='text' readOnly className='col-8' name='department' value={department} onChange={e => setdepartment(e.target.value)} />
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-4'><b> Email Id</b></label>
          <input type='text' readOnly className='col-8' name='emailId' value={emailId} onChange={e => setemailId(e.target.value)} />
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-4'><b> Cost Center</b></label>
          <input type='text' readOnly className='col-8' name="costCenter" value={costCenter} onChange={e => setcostCenter(e.target.value)} />
        </div>
       </div>
       <div className='col-6'>
        
       <div className=' col-sm-12 inputs'>
          <label className='col-4'><b> Call Mode </b></label>
          <div className='col-8 select' style={{paddingLeft:"0px",paddingRight:"0px"}}> 
          <Select
            name="callmodeName"
            options={allcallmodes}
            styles={customStyles}
            onChange={(e: any) => {setcallmodeName(e.label);setcallmodeId(e.value)}}
            value={{ value: callmodeId, label: callmodeName }}
          />
          </div>
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-4'><b> Query Details</b></label>
          <div className='col-8 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
             <textarea
                rows={2}
                value={remark}
                onChange={(e) => setremark(e.target.value)}
                                                    
            className='col-12'
                                                    />
          {/* <input type='textarea' className='col-8' name="remark" value={remark} onChange={e => setremark(e.target.value)} /> */}
          </div>
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-4'><b> Status</b></label>
          <div className='col-8 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select
            name="status"
            options={allstatus}
            styles={customStyles}
            onChange={(e: any) => {setstatus(e.label);setstatusId(e.value)}}
            value={{ value: statusId, label: status }}
          />
          </div>
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-4'><b>Status Remark </b></label>
          <div className='col-8 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
             <textarea
                                                    rows={2}
                                                    cols={40}
                                                    value={statusremark}
                                                    onChange={(e) => setstatusremark(e.target.value)}
                                                    className='col-12'
                                                    />
          {/* <input type='textarea' className='col-8' name="remark" value={remark} onChange={e => setremark(e.target.value)} /> */}
          </div>
        </div>

       </div>
       </div>
       </div>
      <div className='btn d-flex justify-content-center'>
        {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""><i className="fa fa-plus" aria-hidden="true"></i> Submit</Link> : <>
          <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to=""><i className="fa fa-edit"></i> Update </Link>
          <button className="btn2 col-2 col-sm-1" onClick={handleDelete} ><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/helpdesklist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
      </div>
    </>
  )
}

export default HelpDesk