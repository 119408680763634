import { Link } from 'react-router-dom';
import axios from 'axios';
const trackBluedart = () => {
  const config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=ISO-8859-1',
        'Host' :'api.bluedart.com',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, Host, Accept',
        'Accept':'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8',
        'Accept-Encoding' :	'gzip, deflate, br',
        'Accept-Language'	:'en-US,en;q=0.5',
        'Connection' :'keep-alive',
        'Sec-Fetch-Dest':	'document',
        'Sec-Fetch-Mode'	:'navigate',
        'Sec-Fetch-Site'	:'none',
        'Sec-Fetch-User'	:'?1',
        'Upgrade-Insecure-Requests'	:'1',
    }
};
axios.get("https://api.bluedart.com/servlet/RoutingServlet?handler=tnt&action=custawbquery&loginid=BOM30649&awb=awb&numbers=20529084553&format=json&lickey=ejg9mnqsgjni2pug8isiomrrolqsinkv&verno=1&scan=1",config).then(
  (data)=>{
    console.log(data)
  }
)
}
function SettingHeader() {
  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <Link className="navbar-item inwordbtn" to="/settinghome" >Settings </Link>
          <Link className="navbar-item inwordbtn" to="/userlist" > Users </Link>
          <Link className="navbar-item inwordbtn" to="/mailroom" > MailRoom </Link>
          <Link className="navbar-item inwordbtn" to="/branchlist" > Branch </Link>
          <button onClick={trackBluedart}> TrackOnlineBuledart </button>
        </ul>
      </div>
    </>

  );
} export default SettingHeader;