import React from 'react'
import { Link } from 'react-router-dom'

const Outcontainerheader = () => {
  return (
    <div>

<div className='row col-12' >

<ul className="nav nav-pills col-12 " id="pills-tab" role="tablist">

  <Link className="navbar-item inwordbtn" to="/outwardloglist" >Outward Log Entry
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/outwarddetailslist" >Outward Detail Entry
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/outwardmail" >Outward Email
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/outwardmenifestlist" >Outward Manifest
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/outwardstatus" >Outward Status
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/uploadstatus" > Status Upload
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/outwardtracking" >Outward Tracking
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/adminmateriallist" >Admin Material Out
  </Link>
</ul>

</div>
    </div>
  )
}

export default Outcontainerheader