import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy =  localStorage.getItem('userId');
// const updatedBy =  localStorage.getItem('userId');
const mailRoomId =  localStorage.getItem('mailRoomId');
const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};
const getOutwardData = async (serviceProvider :Number, modeName : Number, ) =>{
  interface MyObject {
    mailRoomId:any,
    branch:Number,
    modeName?:Number,
    serviceProvider?:Number
  }

let bodyParamter: MyObject = {
  mailRoomId,
  branch,
}
if(modeName !== 0)
  {
    bodyParamter = {
      ...bodyParamter,
      modeName,
  }
}
if(serviceProvider !== 0)
  {
    bodyParamter = {
      ...bodyParamter,
      serviceProvider,
  }
}
  
 return await axios.post(API_URL +"outwarddetail/pending/", bodyParamter, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}


const postManifestData = async (outwarddetails:any,meniDate:any, manifestTime:any, deliveredBy:number, serviceprovidervalue:number,manifeststatusId:number,tobranchId:number) => {
 const tCode = Date.now().toString();
// const manifestParams = await meniDetails.map((data :any)=>{
  //   return {OutwardDetailsId : data, tCode , mailRoomId : mailRoomId, branch : branch, createdBy : createdBy}
  // });
  // console.log("drsDetailsParams", drsDetailsParams)
  const bodyParamenters = {
    outwarddetails:outwarddetails,
    manifestDate:meniDate,
    manifestTime:manifestTime,
    deliveryStaff:deliveredBy,
    serviceProvider:serviceprovidervalue,
    manifestStatus:manifeststatusId,
    toBranch:tobranchId,
    mailRoomId ,
    branch,
    tCode,
    createdBy
  }
console.log("body",bodyParamenters)
  return await axios.post(API_URL +"manifest/register", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}
const getmanifestList =async (manifestDate :any) => {
  const bodyParamenters = {
    mailRoomId,
    branch,
    manifestDate
  }
  // console.log("bodyParamenters", bodyParamenters)
  return await axios.post(API_URL +"manifest/manifestByDate", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}
const deleteManifest =async (id :Number) => {
  return await axios.delete(API_URL +"manifest/"+ id, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const getmanifestDetails = async (id: Number) => {
  return await axios.get(API_URL +"manifest/"+ id, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const sendEmailNotification = async (inwardIdsByEmployee :any) => {
  const bodyParamenters = {
    mailRoomId,
    branch,
    inwardIdsByEmployee,
    baseUrl : process.env.REACT_APP_PUBLIC_URL
  }
  console.log(bodyParamenters)
  return await axios.post(API_URL +"outwarddetail/getmail", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const getPendingmanifest = async () => {
  const bodyParamenters = {
    mailRoomId,
    branch,
  }
  // console.log("bodyParamenters", bodyParamenters)
  return await axios.post(API_URL +"manifest/pendingmanifest", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const getManifestDetailsbyId = async (id:Number) => {
  // console.log("bodyParamenters", bodyParamenters)
  return await axios.get(API_URL +"manifest/pendingmanifestdetails/" +id, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}
const getRtoReasons =async (statusCodeTableId:Number, returnType : Number) => {
  const bodyParamenters = {
    statusCodeTableId,
    returnType,
  }
  return await axios.post(API_URL +"rto/",bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const OutwardManifest = {
  getOutwardData,
  postManifestData,
  getmanifestList,
  deleteManifest,
  getmanifestDetails,
  sendEmailNotification,
  getPendingmanifest,
  getManifestDetailsbyId,
  getRtoReasons
  }
  
  export default OutwardManifest;