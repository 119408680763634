import {useEffect, useState} from 'react';
import '../headerfooter/headerfootercss.css';
import AuthService from '../../services/auth.service';
import { Link, useNavigate } from "react-router-dom";


const HeaderTop = ({hideHeader, skipHeader} :any) => {

  const [userRole, setUserRole] = useState((localStorage.userDetails) ? JSON.parse(localStorage.userDetails).roles : 0)
  let navigate = useNavigate(); 
    const [isFullScreen, setIsFullScreen] = useState(false); // initialize isFullScreen state to false
  const [maximize, setMaximize] = useState("Maximize");

  const Logout = () => {
    AuthService.logout();
    window.location.href = process.env.PUBLIC_URL  
    
  }
  useEffect(() => {
    
    (localStorage.userDetails) ? <></> : navigate('/');  
  }, [])
  
  const handleFullScreen = () => {
    if (!isFullScreen) {
      document.getElementById('root')?.requestFullscreen(); // open full-screen mode
      setIsFullScreen(true);
      setMaximize("Minimize")

    } else if (document.fullscreenElement) {
      document.exitFullscreen(); // exit full-screen mode
      setIsFullScreen(false);
      setMaximize("Maximize")
    }
  };
  return (
    <>
    <div className='col-12 background divHeight inline' id='my-component' style={{ backgroundColor: "#db1c29",alignItems:"center" }} >
     <div className='col-2 skip'> <a onClick={hideHeader}>{skipHeader}</a>  </div>
     <div className='col-10'><Link to="../settingupdates" style={{textDecoration: 'none', color : "white"}}> Welcome  { (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).firstName :"" }  { (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).lastName  : ""} </Link> | 
     {(userRole === 1) ?  <> <a href="./settinghome" style={{textDecoration : 'none', color : "white"}} > Settings   </a> | </> : ""} 
     
      <a onClick={handleFullScreen} > {maximize} </a>
       | <a onClick={Logout}> Logout </a>
</div>
    </div>  
  
    </>
  )
}

export default HeaderTop