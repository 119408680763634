import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import Swal from 'sweetalert2';
import './master1.css'

interface ReceiverProps {
    id: any;
}

const customStyles = {
    option: (provided:any, {isFocused , isSelected } : any) => ({
        ...provided,
        padding: 5,
        zIndex: 1,
        background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected  ? 'hsl(0deg 0% 20% / 65%)' : undefined,
      }),
    control: (prev :any) => ({ ...prev, 
      padding:0,
      width:"100%"

    }),
    menu: (base : any ,{isFocused , isSelected } : any) => ({
        ...base,
        zIndex: 100,
        background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected  ? 'hsl(0deg 0% 20% / 65%)' : undefined,
      }),
  }

const Department: React.FC<ReceiverProps> = ({ }) => {
    const { state } = useLocation();   
    const [routeId, setrouteId] = useState(0)
    const [name, setname] = useState("");
    const [routeName, setrouteName] = useState();
    const [allRoutes, setallRoutes] = useState([]);
   
    const navigate = useNavigate();

    const HandleSubmit = () => {
      if(name !== ""){
        MasterDataMailroomService.PostDepartment(name, routeId).then(
            (data) => {
                if (data) {
                    navigate("/departmentlist");
                }
            },

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: "Please Enter Valid Department Name",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: 'swal-fire-alert', // Add your custom CSS class here
          },
        })
      }
    }
    const HandleEdit = () => {
      if(name !=="") {
        MasterDataMailroomService.PutDepartment(name, routeId, state).then(
            (data) => {
                if (data) {
                    navigate("/departmentlist");
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: "Please Enter Valid Department Name",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: 'swal-fire-alert', // Add your custom CSS class here
          },
        }).then(
          ()=>{
            navigate('../department', { state: state });
          }
        )
      }
    }
    const getRoutesList = async () => {
      await MasterDataMailroomService.GetRoute().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.routeName
          }))
          await setallRoutes(options)
          if (state === null) {
            if (data[0]) {
              await setrouteId(data[0]['id'])
              await setrouteName(data[0]['routeName'])
            }
          } else {
            let routesNames = data.find((item: any) => {
              return item.id === routeId
            });
            if (routesNames !== undefined) {
              setrouteName(routesNames.routeName)
            }
          }
        }
        }
      )
    }
    useEffect(() => {
      getRoutesList();
    }, [])
  

    // get service provider by id
    const GetDepartmentId = async () => {
        if(state){            
        try {
          await MasterDataMailroomService.GetDepartmentId(state).then(
           async (data) => {
                await setname(data.name);             
                await setrouteId(data.routeId); 
                await getRoutesList();            
           }
          );
          
        } catch (error) {     
          console.error(error);
        }
        
    } 
      };
      

      useEffect(()=>{      
        GetDepartmentId();   
      },[])
 // delete service
 const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
        const handleDelete = () => {
            if(state !== undefined){   
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  MasterDataMailroomService.DeleteBranch(state)
                  .catch((error) => {
                    console.log(error);
                    console.log("server responding");
              
                  })
                  Swal.fire({
                    title: "Deleted",
                    icon: 'success',
                    text: "Department has been deleted",
                }).then(function() {
                 window.location.reload()
                })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                )  {
                  swalWithBootstrapButtons.fire(
                    {
                      title: "Cancelled",
                      icon: 'error',
                      text: "Department is safe :)",
                  }).then(function() {          
                 window.location.reload()
                  }
                  )
                }
              })
            }  
            GetDepartmentId();
        } 

        const handleRouteChange = (e:any) => {
          setrouteId(e.value)
          setrouteName(e.label)
        }
        const HandleCancel = () =>{
            setname("")
            setrouteId(0)
            
          }
    return (
        <>
            <MasterHeader />
            <div className='container col-10' >
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Service Provider </p></div>
            <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Department Name</b></label>
                    <input type='text' className='col-8 col-sm-4'  name='name' value={name} onChange={e => setname(e.target.value)} />
                </div>
                          
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Route name</b></label>
                    <Select
                      name="countryTableId"
                      onChange={handleRouteChange}
                      options={allRoutes}
                      className='col-8 col-sm-4 select'
                      styles={customStyles}
                      value={{ value: routeId, label: routeName }}
                    />
                    {/* <input type='text' className='col-8 col-sm-4'  name='name' value={routeName} onChange={e => setrouteName(e.target.value)} /> */}
                </div>

               
            </div>
            <div className='btn d-flex justify-content-center'>
            { (state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""><i className="fa fa-plus" aria-hidden="true"></i> Submit</Link> : <>
                <button className="btn2 col-2 col-sm-1" onClick={HandleEdit} ><i className="fa fa-edit"></i> Update </button>
               <button className="btn2 col-2 col-sm-1" onClick={handleDelete} ><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> </> }
                <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
                <Link className="btn2 col-2 col-sm-1" to="/departmentlist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
            </div>
        </>
    )
}

export default Department