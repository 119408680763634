import React from 'react';
import '../headerfooter/headerfootercss.css';

const Footer = () => {
  return (
    <div style={{position: "fixed", bottom: "0", width: "100%", backgroundColor : "#fff", zIndex :1, borderTop : "1px solid #ccc" }} >  
      <div className="d-flex justify-content-between">
        <div className="name" >MailRoom By &nbsp; <a href='https://mailit.co.in' target='_blank' style={{color:'black'}}>Mailit MailRoom Managment Services Pvt. Ltd. </a> &nbsp;|  { (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).mailRoomName :"" } | { (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branchName  : ""}</div>
        <div className="name" > Maintained By &nbsp;  <a href='https://sanyogsoft.com/' target='_blank' style={{color :"black" }}>  Sanyog Software </a> </div>
      </div>
        <div className="line"></div>
    </div>
  )
}

export default Footer