import '../headerfooter/headerfootercss.css';
import { Link } from 'react-router-dom';
function MasterHeader() {
  // const [showMe, setShowMe] = useState(false);
  //   function toggle(){
  //     setShowMe(!showMe);
  //   }

  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <Link className="navbar-item inwordbtn" to="/masterhome" >Masters </Link>
          <Link className="navbar-item inwordbtn" to="/deliverylist" > Delivery Boy </Link>
          <Link className="navbar-item inwordbtn" to="/routelist" > Routes </Link>
          <Link className="navbar-item inwordbtn" to="/employeelist" > Employee </Link>
          <Link className="navbar-item inwordbtn" to="/shipmentmodelist" > Shipmentmode </Link>
          <Link className="navbar-item inwordbtn" to="/regulatorslist" > Regulators </Link> 
          <Link className="navbar-item inwordbtn" to="/serviceproviderlist" > Serviceprovider </Link>
          <Link className="navbar-item inwordbtn" to="/departmentlist" > Department</Link>
          <Link className="navbar-item inwordbtn" to="/costcenterlist" > Cost Center </Link>
          <Link className="navbar-item inwordbtn" to="/countrylist" > Location </Link>
        </ul>
      </div>
    </>

  );
} export default MasterHeader;