import DashboardHeader from '../DashboardHeader';
import '../dashboard.css';
import Charts from './charts';
import OutwardStatusBarChart from './outwardStatusBarChart';
import DatewiseTable from './outDatewiseTable';
import MonthoutboundTable from './MonthoutboundTable';
import { useEffect, useState } from 'react';
import moment from 'moment';
import DashboardDataService from '../../../services/dashboard.service';


const OutwardDashboard = () => {

  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));

  const [lastDate, setLastDate] = useState(31);
  const [todaysDate, settodaysDate] = useState(moment().format('DD/MM/YYYY'));
  const [dateWiseData, setdateWiseData] = useState([]);
  const [dataforBarChart, setdataforBarChart] = useState([]);
  const [inwardShipmentofMonth, setInwardShipmentofMonth] = useState([]);
  const [months, setMonths] = useState<any>([]);
  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      const [month, year] = selectedOption.value.split('/');
      setSelectedMonth(month);
      setSelectedYear(year);
    }
  };
  useEffect( () => {
    const monthsData: any = [];
    for (let i = 0; i < 12; i++) {
      const month = moment().clone().subtract(i, 'months');
      const monthLabel = month.format('MMMM');
      const yearLabel = month.format('YYYY');
      const value = month.format('MM/YYYY');
      monthsData.push({ label: `${monthLabel} ${yearLabel}`, value });    
    }
    setMonths(monthsData);
    getDateWiseInwardShipment();  
    getinwardStatusForBarChart();
    inwardshipmentofmonth();
  }, []);
  
  useEffect(()=>{       
    getinwardStatusForBarChart();
    getDateWiseInwardShipment();
    inwardshipmentofmonth();
    const dts = selectedYear + '-'+selectedMonth+'-01'
    const ldate = moment(dts).endOf('month').format("DD");
      setLastDate(parseInt(ldate))     
  },[selectedMonth])


  


  // ------  DatwWise Shipment -------
  const getDateWiseInwardShipment = async () => {
   await DashboardDataService.getDateWiseOutwardShipment(selectedMonth, selectedYear).then(
      async (data) => {
      await setdateWiseData(data)
      }).catch((error) => {
      console.log(error);
      // console.log("server Responding");
    })
  }

  const getinwardStatusForBarChart = async () => {
    await DashboardDataService.getoutwardstatusofmonth(selectedMonth, selectedYear).then(
      async (data) => {
        if(data !==""){
          const options = data.map((d: any) => ({
            "value": d.count,
            "name": d.description
        }))
        await setdataforBarChart(options)
        }else {
          const data1 :any = [
            { name: "Page A", value: 4000 },
            { name: "Page B",  value: 3000 },
            { name: "Page C", value: 2000 },
            { name: "Page D",  value: 2780},
            { name: "Page E",   value: 1890 }            
          ];
          await setdataforBarChart(data1)
        }
      }

    ).catch((error) => {
      console.log(error);
      console.log("server Responding");
    }) 
  }
  const inwardshipmentofmonth = async () => {
    await DashboardDataService.outwardshipmentofmonth(selectedMonth, selectedYear).then(
      async (data) => {
        if(!data){          
          await setInwardShipmentofMonth(data)
        } else {
          await setInwardShipmentofMonth(data)
        }
      }

    ).catch((error) => {
      console.log(error);
    }) 
  }
  return (
    <div className='overflow'>
      <DashboardHeader selectedMonth={selectedMonth} selectedYear ={selectedYear} months={months} handleSelectChange={handleSelectChange}/>
       <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>          
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
      <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}> Outbound Dashboard </p></div>
      <div className='width dash-main'>
      <DatewiseTable dateWiseData={dateWiseData} lastDate = {lastDate}/>
      </div>
      <div className='width inline'>
        <div className='container bartable'>
          <OutwardStatusBarChart  dataforBarChart ={dataforBarChart} todaysDate={todaysDate} />
        </div>
        <div className='container paichart'>
          <Charts inwardShipmentofMonth={inwardShipmentofMonth} />
        </div>
      </div>
      {/* <div>
        <MonthoutboundTable />
      </div> */}
    </div>
    </div>
    </div>
    </div>
  )
}

export default OutwardDashboard