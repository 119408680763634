import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy =  localStorage.getItem('userId');
const updatedBy =  localStorage.getItem('userId');
const mailRoomId =  localStorage.getItem('mailRoomId');
const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};

const inwarddetailCreate = async ( inwardDate :string,  inwardTime :string, batchNo:string, modeId :Number,  AWB:string,   serviceProviderId :Number, fromSource : Number, companyName :string, fromBranch : Number,  consignor :Number, consignorName:string, consignerCityName:string,  employeeId : Number, employeeName:string,inwardQuantity:Number,routeId :Number, department:Number,refNo :string, remark:string, mailContent: string
   ) => {
    const bodyParamter = {
      inwardDate,
      inwardTime,
      batchNo ,
      AWB ,
      refNo,
      fromBranch,
      modeName : modeId,
      companyName,
      serviceProvider :serviceProviderId,
      inwardType : fromSource,
      consignerId : consignor,
      consignerName : consignorName ,
      consignerPhoneNumber:'',
      consignerCityName ,
      consignerZipCode : '',
      consignerAreaName :'',
      consignerStateName : '',
      consignerCountryCode :'',
      inwardQuantity ,
      tCode :Date.now().toString(),
      employeeTableId:employeeId ,
      employeeName, 
      inwarddetailStatus :0,
      routeName: routeId,
      department,
      remark,
      mailContent,
      mailRoomId,
      branch,
      createdBy
    }
 return await axios
    .post(API_URL + "inwarddetail/register", bodyParamter , config)
    .then((response) => {
      return response.data.message;
    }).catch((error) => {
      console.log(error)
    });
}

const inwarddetailUpdate = async ( inwardDate :string,  inwardTime :string, batchNo:string, modeId :Number,  AWB:string,   serviceProviderId :Number, fromSource : Number, companyName :string,fromBranch : Number,  consignor :Number, consignorName:string, consignerCityName:string,  employeeId : Number, employeeName:string,inwardQuantity:Number,routeId :Number, department:Number,refNo :string, remark:string, mailContent: string, id: Number
  ) => {
   const bodyParamter = {
     inwardDate,
     inwardTime,
     batchNo ,
     AWB ,
     refNo,
     fromBranch,
     modeName : modeId,
     serviceProvider :serviceProviderId,
     inwardType : fromSource,
     consignerId : consignor,
     companyName,
     consignerName : consignorName ,
     consignerPhoneNumber:'',
     consignerCityName,
     consignerZipCode : '',
     consignerAreaName :'',
     consignerStateName : '',
     consignerCountryCode :'',
     inwardQuantity ,
     tCode :Date.now().toString(),
     employeeTableId:employeeId ,
     employeeName, 
     inwarddetailStatus :0,
     routeName: routeId,
     department,
     remark,
     mailContent,
     mailRoomId,
     branch,
     updatedBy
   }
return await axios
   .put(API_URL + "inwarddetail/"+ id, bodyParamter , config)
   .then((response) => {
     return response.data.message;
   }).catch((error) => {
     console.log(error)
   });
}

const getInwardDetailsList = async (inwardDate : any) => {
  const bodyParamter = {
    mailRoomId,      
    branch,
    inwardDate
    };
  return await axios.post(API_URL + "inwarddetail/", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const deleteInwardDetails = async (id:Number) => {
  return await axios.delete(API_URL + "inwarddetail/" + id, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const getInwardDetailsById = async (id:Number) => {
  return await axios.get(API_URL + "inwarddetail/" + id, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}
const getInwardDetailsPendigtoDeliveyList = async () => {
  const bodyParamenters = {
    mailRoomId,
    branch
  }
  // return await axios.post(API_URL + "inwarddetail/pending" , bodyParamenters, config).then(
  return await axios.post(API_URL + "inwarddetail/nomailintimation" , bodyParamenters, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}
const travellinghistory= async (AWB:any) => {
  const bodyParamter = {
    mailRoomId,      
    branch,
    AWB
    };
  return await axios.post(API_URL + "inwarddetail/travellinghistory/", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {   
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const getBranchAWBNumber = async () => {
  const bodyParamenters = {
    mailRoomId,
    branch
  }
  console.log("bodyParamenters", bodyParamenters)
  return await axios.post(API_URL + "inwarddetail/getbranchawb" , bodyParamenters, config).then(
    (response) => {
      console.log("response", response)
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error)) 
}


const Inwarddetailsercice = {
  inwarddetailCreate,
  inwarddetailUpdate,
  getInwardDetailsList,
  getInwardDetailsById,
  deleteInwardDetails,
  getInwardDetailsPendigtoDeliveyList,
  travellinghistory,
  getBranchAWBNumber
}

export default Inwarddetailsercice;