import React, { useEffect, useState } from 'react'
import SettingHeader from './settingHeader';
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import MasterDataService from '../../services/masterData.service';
import Select from 'react-select';
import { getRoles } from '@testing-library/react';

interface ReceiverProps {
    id: any;
}

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}

const Users: React.FC<ReceiverProps> = ({ }) => {
    const { state } = useLocation(); 
  
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('');
   
    const [email, setemail] = useState('');
    const [fullAddress, setfullAddress] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [username, setusername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('');
    const [allBranch, setallBranch] = useState([]);
    const [branch, setBranch] = useState(0);
    const [branchLabel, setBranchLabel] = useState('');
    const [roles, setroles] = useState(0);
    const [allRoles, setAllRoles] = useState([]);
    const [rolesLabel, setRolesLabel] = useState('');
    const [readonly, setReadonly] = useState(false);
    

    const navigate = useNavigate();

    const HandleSubmit = () => {
        MasterDataMailroomService.postUsers(firstName, lastName, fullAddress, phoneNumber, email, username, password, branch, roles).then(
            (data) => {
                if (data !== '') {
                    navigate("../userlist");
                }
            },

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
    }
    const HandleEdit = () => {
        MasterDataMailroomService.putUsers(firstName, lastName, fullAddress, phoneNumber, email, username, password, branch,roles, state).then(
            (data) => {
                if (data !== '') {
                    navigate("../userlist");
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
    }

    // get service provider by id
    const GetUserById = async () => {
        if(state){ 
          setReadonly(true)      ;     
        try {
          await MasterDataMailroomService.GetUserById(state).then(
           async (data) => {
            console.log(data ," On edit")
                await setfirstName(data.firstName)
                await setlastName(data.lastName);
                await setemail(data.email)
                await setusername(data.username);
                await setfullAddress(data.fullAddress)
                await setphoneNumber(data.phoneNumber)     
                await setBranch(data.branch);      
                await setroles(data.roles) 
                await setRolesLabel(data.roleName);
           }
          );
          
        } catch (error) {     
          console.error(error);
        }
        
    } 
      };
      

      useEffect(()=>{  
        GetUserById(); 
        getBranch();  
        getUserRoles();
        getUserRoles();
      },[])

//  get branch options 
  const getBranch = async () => {
    await MasterDataService.getBranch().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.branchName
        }))
        await setallBranch(options)
        if(state === null){
          if (data[0]) {
        await setBranch(data[0]['id'])
        await setBranchLabel(data[0]['branchName'])
          }} else {
            branchChange()
          }
      }
      }
    )
  }

  useEffect(()=> {
    branchChange();
  }, [branch, allBranch])
  const branchChange = () => {
    let branchLists = allBranch.find((item: any) => {
      return item.value === branch
    });
    if (branchLists !== undefined) {
      setBranchLabel(branchLists['label'])
    }
  }
  const getUserRoles = async () => {
    await MasterDataService.getRoles().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.roleName
        }))
        await setAllRoles(options)
        if(state === null){
          if (data[0]) {
        await setroles(data[0]['id'])
        await setRolesLabel(data[0]['roleName'])
          }} else {
            let roleList = data.find((item: any) => {
              return item.id === branch
            });
            if (roleList !== undefined) {
              setBranchLabel(roleList.roleName)
            }
          }
      }
      }
    )
  }
        const HandleCancel = () =>{
            setfirstName("")
            setlastName("")
            
          }
    return (
        <>
            <SettingHeader />
            <div className='container col-10'style={{ paddingLeft: "0px" }} >
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Users Register </p></div>

                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> First Name</b></label>
                    <input type='text' className='col-8 col-sm-4' name='firstName' value={firstName} onChange={e => setfirstName(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Last Name</b></label>
                    <input type='text' className='col-8 col-sm-4'  name='lastName' value={lastName} onChange={e => setlastName(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Full Address</b></label>
                    <input type='text' className='col-8 col-sm-4'  name='fullAddress' value={fullAddress} onChange={e => setfullAddress(e.target.value)} />
                </div>

                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Phone Number</b></label>
                    <input type='text' className='col-8 col-sm-4'  name='phoneNumber' value={phoneNumber} onChange={e => setphoneNumber(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Email </b></label>
                    <input type='email' className='col-8 col-sm-4'  name='email' value={email} onChange={e => setemail(e.target.value)} />
                </div>
               
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Username </b></label>
                    <input type='email' className='col-8 col-sm-4'  name='username' value={username} onChange={e => setusername(e.target.value)} readOnly={readonly} />
                </div>
                
            <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Password </b></label>
                    <input type='email' className='col-8 col-sm-4'  name='password' value={password} onChange={e => setPassword(e.target.value)}  readOnly={readonly}/>
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Confirm Password </b></label>
                    <input type='email' className='col-8 col-sm-4'  name='password' value={confirmpassword} onChange={(e :any) =>{ setconfirmpassword(e.target.value)}}  readOnly={readonly} style={{ borderColor : (confirmpassword !== password) ? "red" : ""  }}/>
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Branch </b></label>
                    <Select
                  name="branch"
                  onChange={(e:any) => {setBranch(e.value); setBranchLabel(e.label)}}
                  options={allBranch}
                  value={{ value: branch, label: branchLabel }}
                  className='col-4'
                  styles={customStyles}
                />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> User Role </b></label>
                    <Select
                  name="roles"
                  onChange={(e:any) => {setroles(e.value); setRolesLabel(e.label)}}
                  options={allRoles}
                  value={{ value: roles, label: rolesLabel }}
                  className='col-4'
                  styles={customStyles}
                />
                </div>
                
            </div>
            <div className='btn d-flex justify-content-center'>
            { (state === null) ? <button className="btn2 col-2 col-sm-1" onClick={HandleSubmit} ><i className="fa fa-plus" aria-hidden="true" ></i> Submit</button> : <>
                <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="/userlist"><i className="fa fa-edit"></i> Update </Link>
            </> }
                <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
                <Link className="btn2 col-2 col-sm-1" to="/userlist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
            </div>
        </>
    )
}

export default Users