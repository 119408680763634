import { Link } from 'react-router-dom';
function LocationHeader() {
  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <Link className="navbar-item inwordbtn" to="/countrylist" >Country </Link>
          <Link className="navbar-item inwordbtn" to="/statelist" > State </Link>
          <Link className="navbar-item inwordbtn" to="/citylist" > City </Link>
          <Link className="navbar-item inwordbtn" to="/pincodelist" > PinCode /ZIP Code </Link>
          <Link className="navbar-item inwordbtn" to="/zonelist" >Zone Details </Link>
        </ul>
      </div>
    </>

  );
} export default LocationHeader;