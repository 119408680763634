import InwardContainerHeader from '../inward/InwardContainerHeader';
import Outcontainerheader from '../outward/Outcontainerheader';
function TransactionHeader() {
  return (
    <>
    <div className='row col-12'>
    <div className='col-6'>
      {/* <div className='row col-12'>
        <ul className="nav nav-pills col-12" role="tablist">
          <Link className='inwordbtn' to='../dashboard'>Inward Dashboard  </Link>
        </ul>
      </div> */}
      <div className='row col-12'>
<InwardContainerHeader />
        </div>
        </div>
        <div className='col-6'>
        {/* <div className='row col-12'>
        <ul className="nav nav-pills col-12" role="tablist">
        <Link className='inwordbtn' to='../outwarddashboard'>Outward Dashboard</Link>
        </ul>
      </div> */}
      <div className='row col-12'>
<Outcontainerheader />
        </div>
        </div>
        </div>
    </>

  );
} export default TransactionHeader;