import React, {  ChangeEventHandler } from 'react';

interface InputFiledProps {
  label: string,
  type :string,
  placeholder : string,
  value : string,
  name:string,
  className : string,
  onClickFunction: ChangeEventHandler<HTMLInputElement>
}

const InputField = ({ label, placeholder,type, value, name, className, onClickFunction}:InputFiledProps) => {
  return (
  <>
  <div className="form-group">
    {/* {label && <label htmlFor={name}>{label}</label>} */}
   <input 
   type={type} 
     className="form-control"
     value={value}
     name={name}
      onChange={onClickFunction}
      placeholder={placeholder}
     />
   </div>
  </>
  )
}

export default InputField