import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import AccountHeader from '../accountHeader';
import VendorHeader from './vendorHeader';
import AccountService from '../../../services/accountData.service';

function RateContractList() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRecords, setSelectedRecords] = useState();
    const [allRateContracts, setallRateContracts] = useState([])
    //Table Start
    interface selectRowType {
        mode: any;
        clickToSelect: boolean;  
        style: any;
        onSelect: any;
    }
    const selectRow: selectRowType = {
        mode: "radio",
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' },
        onSelect: (row: any) => {
            setSelectedRecords(row.id);
        }
    }
    interface Article {
        options: any,
        currSizePerPage: any,
        onSizePerPageChange: any,
    }
    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }: Article) => (
        <div className="btn-group" role="group">
            {
                options.map((option: any) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                        <button
                            key={option.text}
                            type="button"
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                        >
                            {option.text}
                        </button>
                    );
                })
            }
        </div>
    );

    interface optionsType {
        sizePerPageRenderer: any
    }
    const options: optionsType = {
        sizePerPageRenderer
    };

    const pagination = paginationFactory(options);
    const columns: ColumnDescription<selectRowType>[] = [

        {
            dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1;
            }, text: 'id', align: 'left', headerClasses: 'left-aligned-header'
        },
        { dataField: 'productName', text: 'Product Name', align: 'left', headerClasses: 'left-aligned-header' },
        { dataField: 'zoneName', text: 'Zone Name', align: 'left', headerClasses: 'left-aligned-header' },
        { dataField: 'shipmentModeName', text: 'Mode Name', align: 'left', headerClasses: 'left-aligned-header' },
        { dataField: 'serviceProviderName', text: 'Service Provider', align: 'left', headerClasses: 'left-aligned-header' },

    ];
    //Table End


    const getRateContracts = async () => {
        await AccountService.getRateContracts().then(
            (data) => {
                setallRateContracts(data);
            }
        )
    }
    useEffect(() => {
        getRateContracts();
    }, [])


    const navigate = useNavigate();
    const editProduct = () => {
        if (selectedRecords !== undefined) {
            navigate('/ratecontract', { state: selectedRecords });
        }
    }
    const filteredData = Array.isArray(allRateContracts) ? allRateContracts.filter((selectedRecords) => {
        const values = Object.values(selectedRecords);
        return values.some((value: unknown): value is string =>
            typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        );

    }) : [];
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const HandleDelete = () => {
        if (selectedRecords !== undefined) {
            console.log("selectedRecords " + selectedRecords);
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    AccountService.deleteProduct(selectedRecords)
                        .catch((error) => {
                            console.log(error);
                            console.log("server responding");

                        })
                    Swal.fire({
                        title: "Deleted",
                        icon: 'success',
                        text: "Contract has been deleted",
                    }).then(function () {
                        window.location.reload()
                    })
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        {
                            title: "Cancelled",
                            icon: 'error',
                            text: "Contract is safe :)",
                        }).then(function () {
                            window.location.reload()
                        }
                        )
                }
            })

        }
    }
    return (
        <>

            <AccountHeader />
            <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                    <div className="container col-10" style={{padding:"0px"}}>
                        <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Rate Contract List</p></div>

                        
                        <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent:"space-between", alignItems: "center" }}>
                       <div className='col-6'> <VendorHeader /></div>
                            <input
                                className='searchbar col-3'
                                type='text'
                                value={searchQuery}
                                placeholder='Search Here ...'
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "center" }}
                            keyField='id'
                            striped
                            hover
                            condensed
                            // data={allServiceProvider}
                            data={filteredData}
                            columns={columns}
                            pagination={pagination}
                            selectRow={selectRow}
                            filter={filterFactory()}
                            filterPosition="top"
                        />

                    </div>
                    <div className='row col-12 d-flex justify-content-center'>
                        <Link to="/ratecontract" className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>  Add</Link>
                        <button className="btn2 col-2 col-sm-1" onClick={editProduct} ><i className="fa fa-edit"></i>  Edit </button>
                        <Link className="btn2 col-2 col-sm-1" onClick={HandleDelete} to="../ratecontractlist"><i className='fa fa-trash'></i>  Delete</Link>
                        <Link className="btn2 col-2 col-sm-1" to="../dashboard"><i className="fa fa-sign-out"></i>  Close</Link>

                    </div>
                </div>
            </div>

        </>
    );
}
export default RateContractList
