import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import Swal from 'sweetalert2';
import './master1.css'

interface ReceiverProps {
    id: any;
}

const CostCenter: React.FC<ReceiverProps> = ({ }) => {
    const { state } = useLocation(); 
  
    const [costCenter, setcostCenter] = useState('')
    const [billingDept, setbillingDept] = useState('');
   

    const serviceProvider = [{

        costCenter: costCenter,
        billingDept: billingDept,
        createdBy: 1,
        mailRoomId: 1
    }]

    const navigate = useNavigate();

    const HandleSubmit = () => {
      if(costCenter !== ""){
        MasterDataMailroomService.PostCostCenter(costCenter, billingDept).then(
            (data) => {
                if (data) {
                    navigate("/costcenterlist");
                }
            },

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: "Please Enter Cost Center Name",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: 'swal-fire-alert', // Add your custom CSS class here
          },
        })
      }
    }
    const HandleEdit = () => {
      if(costCenter !== ""){
        MasterDataMailroomService.PutCostCenter(costCenter, billingDept, state).then(
            (data) => {
                if (data) {
                    navigate("/costcenterlist");
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: "Please Enter Cost Center Name",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: 'swal-fire-alert', // Add your custom CSS class here
          },
        }).then(
          ()=>{
            navigate('/costcenter', { state: state });
          }
        )
      }
    }

    // get service provider by id
    const GetCostCenterId = async () => {
        if(state){            
        try {
          await MasterDataMailroomService.GetCostCenterId(state).then(
           async (data) => {
                await setcostCenter(data.costCenter)
                await setbillingDept(data.billingDept);
               
           }
          );
          
        } catch (error) {     
          console.error(error);
        }
        
    } 
      };
      

      useEffect(()=>{      
        GetCostCenterId();   
      },[])
 // delete service
 const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
        const handleDelete = () => {
            if(state !== undefined){   
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  MasterDataMailroomService.DeleteBranch(state)
                  .catch((error) => {
                    console.log(error);
                    console.log("server responding");
              
                  })
                  Swal.fire({
                    title: "Deleted",
                    icon: 'success',
                    text: "Cost Center has been deleted",
                }).then(function() {
                  navigate("/costcenterlist");
                })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                )  {
                  swalWithBootstrapButtons.fire(
                    {
                      title: "Cancelled",
                      icon: 'error',
                      text: "Cost Center is safe :)",
                  }).then(function() {          
                    navigate("/costcenterlist");
                  }
                  )
                }
              })
            }  
            GetCostCenterId();
        } 

        const HandleCancel = () =>{
            setcostCenter("")
            setbillingDept("")
            
          }
    return (
        <>
            <MasterHeader />
            <div className='container col-10' >
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Cost Center </p></div>

                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Cost Center</b></label>
                    <input type='text' className='col-8 col-sm-4' name='costCenter' value={costCenter} onChange={e => setcostCenter(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Billing Deptartmet</b></label>
                    <input type='text' className='col-8 col-sm-4'  name='billingDept' value={billingDept} onChange={e => setbillingDept(e.target.value)} />
                </div>

               
            </div>
            <div className='btn d-flex justify-content-center'>
            { (state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""><i className="fa fa-plus" aria-hidden="true"></i> Submit</Link> : <>
                <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="/costcenterlist"><i className="fa fa-edit"></i> Update </Link>
               <button className="btn2 col-2 col-sm-1" onClick={handleDelete} ><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> </> }
                <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
                <Link className="btn2 col-2 col-sm-1" to="/costcenterlist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
            </div>
        </>
    )
}

export default CostCenter