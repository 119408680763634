import { Link } from 'react-router-dom';
function VendorHeader() {
  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <Link className="navbar-item inwordbtn" to="/vendorzonelist" > Zone </Link>
          <Link className="navbar-item inwordbtn" to="/productlist" >Product </Link>
          <Link className="navbar-item inwordbtn" to="/ratecontractlist" > Rate Contract </Link>
        </ul>
      </div>
    </>

  );
}
 export default VendorHeader;