import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'

function EmployeeList() {


  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();

const [allEmployee , setallEmployee]= useState([]);

  interface selectRowType {
    mode: any;
    clickToSlect: boolean;
    style: any,
    onSelect: any
  }

interface selectRowType {
  mode: any;
  clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
  style: any;
  onSelect: any;
}

const selectRow: selectRowType = {
  mode: "radio",
  clickToSelect: true,
  style: { backgroundColor: '#c8e6c9' },
  onSelect: (row: any) => {
    setSelectedRecords(row.id);
  },
  clickToSlect: false
}
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [

    { dataField: 'employeeId', text: 'Employee Id' },
    { dataField: 'employeeName', text: ' Name' },
    { dataField: 'emailId', text: 'Email Id' },
    { dataField: 'phoneNumber', text: 'Phone No' },
    { dataField: 'extension', text: 'Extension' },
    { dataField: 'departmentName', text: 'Department' },
    { dataField: 'division', text: 'Division' },
    { dataField: 'floor', text: 'Floor' },
    { dataField: 'costCenterName', text: 'Cost Center' }

  ];
  //Table End
  const getEmployeeList = async () =>{
    await MasterDataService.getEmployeeList().then(
        (data) =>{
            setallEmployee(data);
        }
    )
  }
  useEffect(()=>{
    getEmployeeList();
  },[])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  
  const HandleDelete = () => {
    if(selectedRecords !== undefined){   
      
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MasterDataService.deleteEmployee(selectedRecords)
        .catch((error) => {
          console.log(error);
          console.log("server responding");
    
        })
        Swal.fire({
          title: "Deleted",
          icon: 'success',
          text: "Employee has been deleted",
      }).then(function() {
       window.location.reload()
      })
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      )  {
        swalWithBootstrapButtons.fire(
          {
            title: "Cancelled",
            icon: 'error',
            text: "Employee is safe :)",
        }).then(function() {          
       window.location.reload()
        }
        )
      }
    })
  }
  } 

  const navigate = useNavigate();
  const EditDelivery = () =>{
    if(selectedRecords !== undefined){   
    navigate('../employee', { state: selectedRecords });
    }
  }
  
  const filteredData = Array.isArray(allEmployee)
  ? allEmployee.filter((selectedRecords) => {
    const values = Object.values(selectedRecords);
    return values.some((value: unknown): value is string =>
      typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }) : [];
  return (
    <>
      <MasterHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-10">
          <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}> Employee List</p></div>

          <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end",alignItems:"center" }}>
              <input
                className='searchbar col-3'
                type='text'
                value={searchQuery}
                placeholder='Search Here ...'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="table-responsive">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%' }} 
              keyField='id'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
             </div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
          <Link to="/employee" className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
          <button className="btn2 col-2 col-sm-1" onClick={EditDelivery}><i className="fa fa-edit"></i> Edit</button>
          <button className="btn2 col-2 col-sm-1" onClick={HandleDelete}><i className="fa fa-trash"></i> Delete</button>
          <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
          
        </div>
        </div> 
      </div>
    </>
  );
}

export default EmployeeList