// import '../inward/inwarddetail.css';
import '../inward/inwardStatus.css';
import InwardContainerHeader from './InwardContainerHeader';
import { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import Inwarddetailsercice from "../../services/inwarddetail.service";
import InwardLogService from '../../services/inwardlog.service';
import moment from 'moment';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import Swal from 'sweetalert2';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';


function InwardTracking() {
  const navigate = useNavigate();
  const [batchNo, setbatchNo] = useState("");
  const [logDateTime, setLogDateTime] = useState("");
  const [mode, setmodeId] = useState("");
  const [AwbNo, setAwbNo] = useState("");
  const [NumberOfPcs, setNumberOfPcs] = useState("");
  const [LogRemark, setLogRemark] = useState("");
  const [IsrNo, setIsrNo] = useState("");
  const [DetailUser, setDetailUser] = useState("");
  const [StatusUser, setStatusUser] = useState("");
  const [EntryBranch, setEntryBranch] = useState("");
  const [Consignor, setConsignor] = useState("");
  const [City, setCity] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Reference, setReference] = useState("");
  const [Employee, setEmployee] = useState("");
  const [Status, setStatus] = useState("");
  const [Department, setDepartment] = useState("");
  const [statuscode, setstatuscode] = useState("");
  const [RecTime, setRecTime] = useState("");
  const [statusRemarks, setstatusRemarks] = useState("");
  const [drsno, setdrsno] = useState<any |''>("");
  const [RecBy, setRecBy] = useState("");
  const [routeName, setRouteName] = useState("");
  const [Branch, setBranch] = useState("");
  const [travellingdetails, setTravellingdetails] = useState([])
  const [statusupdatediv, setstatusupdatediv] = useState('none')
  const [deliverySign, setdeliverySign] = useState("")
  const [statusdate, setstatusdate] = useState((moment(new Date())).format('YYYY-MM-DD'))
  const [statusTime, setstatusTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
  const [receivedByname, setReceivedByname] = useState(false);
  const [receivedBy, setreceivedBy] = useState("");
  const [statusremark, setstatusremark] = useState('')
  const [inwarddetailStatus, setinwarddetailStatus] = useState(0);
  const [statusReasons, setstatusReasons] = useState([]);
  const [ReasonId, setReasonId] = useState(0);
  const [drsId, setdrsId] = useState(0);
  const [ReasonLabel, setReasonLabel] = useState('');
  const [inwarddetailid, setinwarddetailid] = useState(0);
  const [missRouteReason, setmissRouteReason] = useState("")
  const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setinwarddetailStatus(Number(event.target.value));
    if (event.target.value === '9') { setReasonId(0) }
    if (event.target.value === '8') {
      setReceivedByname(true)
    } else {
      setReceivedByname(false)
    }
  };





  const [legUser, setLegUser] = useState('');


  // const [searchQuery, setSearchQuery] = useState('');
  const [mailContent, setmailContent] = useState();
  // const [allInwardDetails, setAllInwardDetails] = useState([]);
  const { state } = useLocation();

  const getTrackingdetails = () => {

    travellinghistory(AwbNo)
  }
  useEffect(() => {
    travellinghistory(state)
  }, [])
  const travellinghistory = async (AWB: any) => {

    await Inwarddetailsercice.travellinghistory(AWB).then(
      (data) => {
        if (data !== undefined) {
          setinwarddetailid(data.id)
          setbatchNo(data.batchNo);
          if (data.logDateTime !== "") {
            setLogDateTime(moment(data.logDateTime).format("DD/MM/YYYY HH:MM:SS A"));
          }

          setmodeId(data.modeName);
          setAwbNo(data.AWB);
          setNumberOfPcs(data.inwardQuantity);
          setLogRemark(data.remark);
          setIsrNo(data.isrNumber);
          // setTerminal(data.);
          setLegUser(data.createdBy);
          setDetailUser(data.createdBy);
          setStatusUser(data.createdBy);
          setEntryBranch(data.branch);
          setConsignor(data.consignerName);
          setcompanyName(data.companyName);
          setCity(data.consignerCityName);
          setPincode(data.consignerZipCode);
          setReference(data.refNo);
          setEmployee(data.employeeName);
          setDepartment(data.employeeDepartment);
          setBranch(data.employeeBranch)
          setRouteName(data.employeeRoute)
          setmailContent(data.mailContent)
          //  setEmail(data.);
          // setCc(data);
          setStatus(data.inwarddetailStatusDescription);
          setRecBy(data.receivedBy);
          if(data.deliveryDateTime !== "") {  setRecTime(moment(data.deliveryDateTime).format('DD/MM/YYYY HH:mm:ss'));}
        
          setTravellingdetails(data.travellingDetails)
          // console.log("data.travellingDetails", data.travellingDetails)
          setdeliverySign(data.deliverySign)
          if ((data.inwarddetailStatus !== 8) || (data.inwarddetailStatus !== 9) || (data.inwarddetailStatus !== 11)) {
            setstatusupdatediv('block')
          }
          setmissRouteReason(data.missRouteReason)
        
          //  console.log("data.travellingDetails", data.travellingDetails)
          //    setRecDtTm(data.);
          //setHfNo(data);
             setstatusRemarks(data.remark);
        } else {
          if (AwbNo !== "") {
            Swal.fire({
              title: "Not Found",
              icon: 'warning',
              text: "Searching AWB Details Not Found Please Check",
            })
          }
          // alert("AWB Details Not Found Please Check")
        }

      }
    );
  }

  const UpdateStatus = () => {
    if (inwarddetailStatus !== null) {
      let deliverySignbase64 = "";
      console.log("receivedBy", receivedBy)
      InwardLogService.postupdateinwarddetailstatus(inwarddetailid, inwarddetailStatus, receivedBy, statusremark, statusdate, statusTime, deliverySignbase64, ReasonId, ReasonLabel, drsId).then(
        (data) => {
          if (data) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "Inward Status Updated Successfully",
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                title: 'swal-fire-alert', // Add your custom CSS class here
              },
            }).then(function () {
              navigate('/inworddetailslist');
            });
          }
        }
      ).catch((error) => {
        console.log(error);
        console.log("server responding");
      })
    }
  }

  useEffect(() => {
    getRtoReasons()
  }, [inwarddetailStatus])
  const getRtoReasons = () => {
    InwardDeliverySheet.getRtoReasons(inwarddetailStatus, 1).then(
      (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.returnReason
          }))
          setstatusReasons(options)
        }
      }
    ).catch((error) => {
      console.log(error);
      console.log("server responding");
    })
  }

  const handleInputChangeAwb = async (input: any) => {
    if (input.length > 3) {
      return await InwardDeliverySheet.getAwbNumberbyAutoComplte(input).then(
        (data) => {
          const awbNumberList = data.map((d: any) => ({
            "value": d.AWB,
            "label": d.AWB
          }))
          return awbNumberList;
        }
      )
    }
  };


  return (
    <>


      <div style={{ margin: "0px" }}>
        <div className='inline'>
          <div className='col-9'> <InwardContainerHeader /></div>
          <div className='col-3 inline'> 
          <div className='col-9'>
          <AsyncSelect name="AwbNo"
                          // onInputChange={(input) => setInputValue(input)}
                          onChange={(e: any) => { setAwbNo(e.value); travellinghistory(e.value) }}
                          loadOptions={handleInputChangeAwb}
                          value={{ value: AwbNo, label: AwbNo }}
                          className='col-12'
                        />
                        </div>
          {/* <input type="text" style={{ padding: '4px' }} value={AwbNo} onChange={(e) => { setAwbNo(e.target.value) }} />  */}
          
          {/* <button className='inwordbtn col-3' onClick={getTrackingdetails}> <i className='fa fa-search'>Search</i> </button> */}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%", paddingBottom: '5%' }}>
          <div className="container col-10" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Travelling Details </p></div>

              <div className='col-12 inline'>
                <div className='col-4'>
                  <table>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={2}>
                          Shipper Details
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Batch No :  </td>
                        <td>  {batchNo}</td>
                      </tr>
                      <tr>
                        <td>Log Dt/Tm:  </td>
                        <td>  {logDateTime}</td>
                      </tr>
                      <tr>
                        <td>Mode : </td>
                        <td> {mode}</td>
                      </tr>
                      <tr>
                        <td>Awb No : </td>
                        <td>  {AwbNo}</td>
                      </tr>
                      <tr>
                        <td>NO of Pcs:{NumberOfPcs} </td>
                        <td>  {NumberOfPcs}</td>
                      </tr>
                      <tr>
                        <td>Mail Content:</td>
                        <td>{mailContent}</td>
                      </tr>
                      <tr>
                        <td>Log Remark:</td>
                        <td>{LogRemark}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className='col-12'>

                    <thead>
                      <tr className='themeColor'>  <td colSpan={2}> Entry User Details</td>  </tr>
                    </thead>
                    <tbody>
                      <tr> <td>ISR No :</td> <td>{IsrNo}</td></tr>
                      <tr> <td>Log User :</td> <td>{DetailUser}</td></tr>
                      <tr> <td>Entry Branch :</td> <td>{EntryBranch}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className='col-4' style={{ borderLeft: '2px solid #ccc' }}>
                  <table>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={2}> Consignor Details</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> <td>Consignor: </td> <td>{Consignor}</td></tr>
                      <tr> <td>Company : </td> <td>{companyName}</td></tr>
                      <tr> <td>City: </td> <td>{City} { Pincode }</td></tr>
                      <tr> <td>Reference: </td> <td>{Reference}</td></tr>
                      <tr> <td>Remark: </td> <td>{LogRemark}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className='col-4' style={{ borderLeft: '2px solid #ccc' }}>
                  <table className='col-12'>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={2}>
                          Employee Details
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td>Employee:</td><td>{Employee}</td></tr>
                      <tr><td>Depart:</td><td>{Department}</td></tr>
                      <tr><td>Branch:</td><td>{Branch}</td></tr>
                      <tr><td>Route:</td><td>{routeName}</td></tr>
                    </tbody>
                  </table>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={3}>Status details</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td >Status:</td>
                        <td>{Status} <br/> {missRouteReason}</td>
                        <td rowSpan={5} style={{ border: "2px solid #ccc" }}>
                          {(deliverySign) && (<img src={process.env.REACT_APP_BASE_UPLOAD_API + "inwarddetail/deliverySign/" + deliverySign} width="140px" />
                          )}</td></tr>
                      <tr><td>Rec By:</td><td>{RecBy}</td></tr>
                      <tr><td>Rec Dt/Tm:</td><td>{RecTime}</td></tr>
                      <tr><td>DS No:</td><td>{drsno}</td></tr>
                      <tr><td>Status Remark:</td><td>{statusRemarks}</td></tr>

                    </tbody>
                  </table>
                </div>

              </div>
              <div className='col-12 inline'>
                <div className='col-8'>
                  <div className='data-bottom'>
                    <div>
                      <table className="table1 container">
                        <thead className="thead" style={{}}>

                          <tr style={{ textAlign: "left", border: "1px solid grey" }}>
                            {/* <th scope="col">Sr. No</th> */}
                            <th scope="col"  >Date Time</th>
                            <th scope="col"  >Scan Details</th>
                            <th scope="col"  >Remarks</th>
                            <th scope="col">Log Entry By</th>
                          </tr>

                        </thead>


                        <tbody className="width scroll-table " style={{ border: "1px solid grey" }}>
                          {travellingdetails?.map((travels, index) => (
                            <tr key={index}>
                              {/* <th scope="row">{index + 1}</th> */}
                              <td>
                              {moment(travels['travellingDate']).format("DD/MM/YYYY")}{' '}
  {typeof travels['travellingTime'] === 'string' && (travels['travellingTime'] as string).length > 8 ? (
    moment(travels['travellingTime']).format('hh:mm:ss A')
  ) : (
    travels['travellingTime']
  )}
                              </td>
                              <td>{travels['statusCodeDescription']} </td>
                              <td>{travels['entryTransaction']}  {(travels['transactionNumber'] !== null) ? travels['transactionNumber'] : ''}</td>
                              <td>{travels['createdByName']}</td>
                            </tr>
                            // { (travels['statusCodeDescription'] === 'DRS Generated') && setdrsno(travels['entryTransaction'])}
                          ))}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-4 incolumn' style={{ display: statusupdatediv }} >

                  <span className='themeColor'>Update Status Details</span>
                  <div style={{ marginTop: "2px" }}></div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2 inline'>
                            <input type='radio' className='radio-btn'
                              value="8"
                              name="deliveryStatus"
                              checked={inwarddetailStatus === 8}
                              onChange={handleOptionChange1}
                            /> Delivered</label>
                        </td>
                        <td>
                          <label className='Radiobtn col-2 inline'><input type='radio' className='radio-btn'
                            value="9"
                            name="deliveryStatus"
                            checked={inwarddetailStatus === 9}
                            onChange={handleOptionChange1}

                          /> Undelivered</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                            value="16"
                            name="deliveryStatus"
                            checked={inwarddetailStatus === 16}
                            onChange={handleOptionChange1}
                          /> OnHold</label>
                        </td>
                        <td>
                          <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                            value="13"
                            name="deliveryStatus"
                            checked={inwarddetailStatus === 13}
                            onChange={handleOptionChange1}
                          /> Redirected</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2'>
                            <input type='radio' className='radio-btn'
                              value="14"
                              name="deliveryStatus"
                              checked={inwarddetailStatus === 14}
                              onChange={handleOptionChange1}
                            /> Unclaimed</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="date" max={moment().format("YYYY-MM-DD")} value={statusdate} onChange={(e: any) => { setstatusdate(e.value) }} />
                        </td>
                        <td>
                          <input type="time" value={statusTime} onChange={(e: any) => { setstatusTime(e.value) }} />
                        </td>

                      </tr>
                      {receivedByname && (<tr>
                        <td colSpan={2}>
                          <input type="text" value={receivedBy} onChange={(e: any) => {  setreceivedBy(e.target.value) }} placeholder='Received by Name' />
                        </td>
                      </tr>)}
                      {(statusReasons.length !== 0) && (
                        <tr>
                          <td colSpan={2} className='select' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <Select
                              name="ReasonId"
                              onChange={(e: any) => { setReasonId(e.value); setReasonLabel(e.label) }}
                              options={statusReasons}
                              value={{ value: ReasonId, label: ReasonLabel }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={2}>
                          <input type="text" value={statusremark} onChange={(e: any) => { setstatusremark(e.target.value) }} placeholder='Remark if any' />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ textAlign: 'right' }}>
                          <button className='btn2' style={{ textAlign: 'right' }} onClick={UpdateStatus}> Update Status </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                </div>
              </div>
          </div>


        </div>


      </div>
    </>



  );
} export default InwardTracking;