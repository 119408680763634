import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
// const createdBy = localStorage.getItem('userId');
// const updatedBy = localStorage.getItem('userId');
const mailRoomId = localStorage.getItem('mailRoomId');
// const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};
// --------------------Product -------------
const putmailroom = async (mailRoomName:string,mailRoomOwner:string,logo:any,emailAddress:string,whatsAppNumber:string,whatsAppInstanceId:string,whatsAppLink:string,smtpServer:string,smtpPort:string,smtpEmail:string,smtpPassword:string) => {
    const bodyParamter = {
        mailRoomName,mailRoomOwner,filename : logo,emailAddress,whatsAppNumber,whatsAppInstanceId,whatsAppLink,smtpServer,smtpPort,smtpEmail,smtpPassword,
        mailRoomId
        };

    return axios.put(API_URL + "mailroom/"+mailRoomId, bodyParamter, config).then(
        (response) => {    
            return response.data.message;
        }
    )
        .catch(error => console.log(error));

}
const getMailroom = async () => {
    return axios.get(API_URL + "mailroom/", config).then(
        (response) => {
            return response.data.message
            
        })
        .catch(error => console.log(error))
}

const MailroomService = {
    putmailroom,
    getMailroom

}

export default MailroomService;