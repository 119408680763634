import { ChangeEvent, useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom';
import MailroomService from '../../services/mailroom.service';
import SettingHeader from './settingHeader';

const UPLOAD_URL = process.env.REACT_APP_BASE_UPLOAD_API;



const Mailroom = () => {
    const [mailRoomName, setmailRoomName] = useState('');
    const [mailRoomOwner, setmailRoomOwner] = useState('');
    const [logo, setlogo] = useState<string | undefined>();
    const [logoURL, setLogoURL] = useState<string | undefined>(UPLOAD_URL + "mailroom/");
    const [emailAddress, setemailAddress] = useState('');
    const [whatsAppNumber, setwhatsAppNumber] = useState('');
    const [whatsAppInstanceId, setwhatsAppInstanceId] = useState('');
    const [whatsAppLink, setwhatsAppLink] = useState('');
    const [smtpServer, setsmtpServer] = useState('');
    const [smtpPort, setsmtpPort] = useState('');
    const [smtpEmail, setsmtpEmail] = useState('');
    const [smtpPassword, setsmtpPassword] = useState('');
    const navigate = useNavigate();

    const getMailroom = async () => {

        try {
            const data = await MailroomService.getMailroom();
            if (data) {
                await setmailRoomName(data[0].mailRoomName);
                await setmailRoomOwner(data[0].mailRoomOwner);
                await setlogo(data[0].logo);
                await setemailAddress(data[0].emailAddress);
                await setwhatsAppNumber(data[0].whatsAppNumber);
                await setwhatsAppInstanceId(data[0].whatsAppInstanceId);
                await setwhatsAppLink(data[0].whatsAppLink);
                await setsmtpServer(data[0].smtpServer);
                await setsmtpPort(data[0].smtpPort);
                await setsmtpEmail(data[0].smtpEmail);
                await setsmtpPassword(data[0].smtpPassword);
            } else {
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        getMailroom();

    }, [])
    const HandleEdit = () => {
        MailroomService.putmailroom(mailRoomName, mailRoomOwner, logo, emailAddress, whatsAppNumber, whatsAppInstanceId, whatsAppLink, smtpServer, smtpPort, smtpEmail, smtpPassword).then(
            (data) => {
                if (data !="") {
                    navigate('../mailroom');
                }
            }
        ).catch((error) => {
            console.log(error);
        })
    }
    const HandleCancel = () => {
    }
    
        const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (file) {
              setlogo(file.name);
              const logoUrl = URL.createObjectURL(file);
              setLogoURL(logoUrl);
            }
          };
  
    return (
        <>
           <SettingHeader />
            <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
                <div className='container col-10' style={{ paddingLeft: "0px" }} >
                    <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Mailroom</p></div>
                    <div className='row inline'>
                        <div className='col-6 pr-0'>
                            <div className='col-12'>
                                <label className='col-5'>Mailroom Name</label>
                                <input type='text' className='col-7' name='mailRoomName' value={mailRoomName} onChange={e => setmailRoomName(e.target.value)} />
                            </div>
                            <div className='col-12'>
                                <label className='col-5'>Mailroom Owner</label>
                                <input type='text' className='col-7' name='mailRoomOwner' value={mailRoomOwner} onChange={e => setmailRoomOwner(e.target.value)} />
                            </div>
                            <div className=' col-12'>
                                <label className='col-5'> WhatsApp Number </label>
                                <input type='text' className='col-7' name='whatsAppNumber' value={whatsAppNumber} onChange={e => setwhatsAppNumber(e.target.value)} />
                            </div>
                            <div className=' col-12'>
                                <label className='col-5'> WhatsApp Instance Id </label>
                                <input type='text' className='col-7' name='whatsAppInstanceId' value={whatsAppInstanceId} onChange={e => setwhatsAppInstanceId(e.target.value)} />
                            </div>
                            <div className=' col-12'>
                                <label className='col-5'> WhatsApp Link </label>
                                <input type='text' className='col-7' name='whatsAppLink' value={whatsAppLink} onChange={e => setwhatsAppLink(e.target.value)} />
                            </div>
                            <div className='col-12 inline'>
                                <label className='col-5'>logo</label>
                                <input type='file' className='col-7' name='filename' onChange={handleLogoChange} />                               
                            </div>
                            <div className='col-12 inline'>
                                <div className='col-5'></div>
                            {logoURL && (
                                <div className='col-7'>
                                    <img src={logoURL} alt='Selected Logo' className='col-12' />
                                </div>
                            )}
                            </div>
                           
                        </div>


                        <div className='col-6 pl-0'>
                        <div className='col-12'>
                                <label className='col-4'>Email Address</label>
                                <input type='mail' className='col-8' name='emailAddress' value={emailAddress} onChange={e => setemailAddress(e.target.value)} />
                            </div>
                            <div className='col-12'>
                                <label className='col-4'>Sending From Email</label>
                                <input type='mail' className='col-8' name='smtpEmail' value={smtpEmail} onChange={e => setsmtpEmail(e.target.value)} />
                            </div>
                            <div className='col-12'>
                                <label className='col-4'>Email Password</label>
                                <input type='password' className='col-8' name='smtpPassword' value={smtpPassword} onChange={e => setsmtpPassword(e.target.value)} />
                            </div>
                            
                            <div className='col-12'>
                                <label className='col-4'> SMTP Server </label>
                                <input type='text' className='col-8' name='smtpServer' value={smtpServer} onChange={e => setsmtpServer(e.target.value)} />
                            </div>
                            <div className='col-12'>
                                <label className='col-4'> SMTP Port </label>
                                <input type='text' className='col-8' name='smtpPort' value={smtpPort} onChange={e => setsmtpPort(e.target.value)} />
                            </div>
                           
                        </div>




                    </div>
                </div>
                <div className='btn d-flex justify-content-center'>
                    <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to=""><i className="fa fa-edit"></i> Update </Link>
                    <Link className="btn2 col-2 col-sm-1" onClick={HandleCancel} to={''}><i className="fa fa-close"></i> Cancel</Link>
                </div>
            </div>
        </>
    )
}

export default Mailroom